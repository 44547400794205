.navbarLoggedin {
  padding: 32px;
  width: 100%;
  height: 108px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  transition: 0.2s ease-in-out;
}

.navbarLoggedin.shadow {
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
}

.mobileLinks {
  display: none;
}

.mobileNav {
  display: none;
}

.leftDiv {
  display: flex;
  gap: 32px;
  align-items: center;
}

.navLinks {
  display: flex;
  gap: 32px;
  transition: 0.3s ease-in-out;
  transform: scaleX(1);
  transform-origin: left;
}

.navLink {
  text-decoration: none;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: var(--camsol-neutral-700);
  position: relative;
  white-space: nowrap;
}

.navLink::before {
  content: '';
  position: absolute;
  width: 80%;
  background-color: var(--platform-secondary-500);
  height: 2px;
  bottom: -1px;
  right: 0;
  transform: scaleX(0);
  transition: 0.2s ease-in-out;
  transform-origin: right;
}

.navLink:hover::before {
  transform: scaleX(0.5);
}

.rightDiv {
  display: flex;
  gap: 23.17px;
  align-items: center;
  justify-content: flex-end;
  height: 40px;
}

.inputFieldDiv {
  width: 270px;
  height: 100%;
  max-width: 70%;
  position: relative;
  transition: 0.3s ease-in-out;
}

.inputFieldDivActive {
  width: 752px;
}

.inputField {
  padding: 10px 12px;
  padding-left: 50px;
  background: #61012c1a;
  border-radius: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  width: 100%;
  height: 100%;
  border: none;
  color: #00090d;
}

.inputField:focus,
.inputField:active {
  outline: none;
}

.iconButton {
  position: absolute;
  background-color: transparent;
  border: none;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  padding-top: 4px;
}

.iconButton:hover {
  background: none !important;
}

.searchButton {
  left: 14.71px;
}

.searchButton:hover {
  background: none !important;
}

.inputFieldDivActive .searchButton {
  /* border-right: 1px solid var(--camsol-neutral-400); */
  padding-right: 10px;
}

.closeButton {
  right: 14.71px;
}

.closeButton:hover {
  background: none !important;
}

.iconDiv {
  display: flex;
  align-items: center;
  gap: 21.37px;
}

.profileImage {
  width: 40px;
  height: 40px;
  border-radius: 4px;
}

.suggestionCardMobile {
  display: none;
}

.suggestionCard {
  position: absolute;
  top: 59px;
  width: 100%;
  background-color: #fff;
  z-index: 10;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  transform: scaleY(0);
  transition: 0.3s ease-in-out;
  transform-origin: top;
  overflow: hidden;
}

.suggestionCardActive {
  transform: scaleY(1);
}

.suggestedDiv {
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 9px;
}

.suggestedText {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: var(--camsol-neutral-600);
}

.suggested {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #000e14;
}

.allSuggestionsDiv {
  display: flex;
  color: var(--platform-secondary-600);
  padding: 12px;
  gap: 8px;
  width: 100%;
  height: 68px;
  background-color: var(--platform-secondary-50);
  align-items: center;
}

.allSuggestionsDiv p a {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin-left: 12px;
  color: var(--platform-secondary-600);
}

.cardsDiv {
  display: flex;
  height: 358px;
  width: 100%;
  padding: 0px 24px 24px;
  gap: 16px;
}

.cardsDiv .card {
  width: 50%;
}

.mobile {
  flex-direction: row;
}

.cardDiv {
  position: relative;
  top: 8px;
  right: 155px;
}

.profileDiv {
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

@media only screen and (max-width: 1300px) {
  .inputFieldDiv {
    max-width: 50%;
  }
}
@media only screen and (max-width: 1200px) {
  .navLink {
    font-size: 14px;
  }
  .inputField {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1024px) {
  .navbar {
    padding: 32px 79px;
  }

  .navLinkDiv {
    gap: 22.7px;
  }

  .navLink {
    font-size: 13px;
  }

  .signupBtn {
    font-size: 11.38px;
    padding: 11.4px 17px;
  }

  .arrow {
    margin-left: 8.2px;
  }

  .logo {
    width: 105px;
  }

  .buttonDiv {
    gap: 21px;
  }

  .inputFieldDiv {
    width: 270px;
  }

  .inputField {
    padding-left: 27px;
  }

  .inputField {
    font-size: 11.4px;
  }

  .inputFieldDivActive .inputField {
    padding: 7.1px 8.5px;
    padding-left: 40.5px;
  }

  .closeButton {
    right: 10.07px;
  }

  .searchButton {
    left: 10.07px;
    padding-right: 8.5px;
  }

  .suggestionCard {
    top: 42px;
    border-radius: 11.4px;
  }

  .suggestedDiv {
    padding: 17px;
    gap: 6.4px;
  }

  .suggestedText {
    font-size: 10px;
    line-height: 12.1px;
  }

  .suggested {
    font-size: 12.8px;
    line-height: 15.6px;
  }

  .allSuggestionsDiv {
    padding: 8.4px;
    gap: 5.6px;
  }

  .allSuggestionsDiv p {
    font-size: 9.8px;
    line-height: 11.9px;
    margin-left: 8.4px;
  }

  .cardsDiv {
    padding: 0px 17.6px 17.6px;
    gap: 11.2px;
  }
}

@media only screen and (max-width: 1024px) {
  .inputFieldDiv {
    width: 230px;
  }
}

@media only screen and (max-width: 1020px) {
  .mobile {
    flex-direction: column;
  }

  .leftDiv,
  .rightDiv {
    display: none;
  }

  .navButton {
    background-color: transparent;
    border: none;
  }

  .mobileNav {
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: #fff;
    gap: 1rem;
  }

  .navbarLoggedin {
    border: none;
  }

  .navbarLoggedMenu {
    border-bottom: 1px solid gray;
  }

  .navLinkDivActive {
    display: block;
  }

  .navLinkDivInactive {
    display: none;
  }

  .activeButton {
    display: block;
  }

  .inActiveButton {
    display: none;
  }

  .navLinkDiv {
    position: absolute;
    top: 82.7px;
    display: flex;
    flex-direction: column;
    border-top: 1px solid black;
    width: 100%;
    padding: 24px 0px;
    gap: 32px;
    padding-right: 16px;
  }

  .navLinkDivInactiveMobile {
    display: none;
  }

  .buttonDivMobileActive {
    display: flex;
  }

  .buttonDivMobileInactive {
    display: none;
  }

  .buttonDivMobileActive {
    display: block;
    width: fit-content;
    height: 48px;
    padding: 16px 24px;
    border-radius: 6px;
    background-color: var(--platform-secondary-700);
    color: white;
    border: none;
  }

  .navLink::before {
    width: 0px;
    height: 0px;
  }

  .navLink {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-right: 16px;
  }

  .dropDown {
    position: absolute;
    top: 16px;
  }

  .inputFieldDivActive {
    width: 250px;
  }

  .inputDivMobile {
    width: 70vw;
    min-width: 300px;
    height: 32px;
    position: relative;
  }

  .inputDivMobile input {
    width: 100%;
    background: #61012c1a;
    border-radius: 4px;
    padding: 8px 13px;
  }

  .inputDivMobile input {
    border: none;
    padding-left: 35px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: var(--camsol-neutral-600);
  }

  .inputDivMobile input:focus,
  .inputDivMobile input:active {
    outline: none;
  }

  .inputSearch {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    padding-right: 10px;
  }

  .suggestionCardMobileActive {
    height: fit-content;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 36px;
    background: #ffffff;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }

  .cardsDiv {
    flex-direction: column;
    padding-bottom: 16px;
  }

  .cardsDiv .card {
    width: 100%;
  }

  .suggested {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.08em;
    color: #000000;
  }

  .suggestedText {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.02em;
    color: var(--camsol-neutral-600);
  }

  .allSuggestionsDiv {
    padding: 8px;
    margin-top: 16px;
  }

  .allSuggestionsDiv p {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.02em;
  }

  .btnContainer {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
  }

  .mobileLinksActive {
    padding: 24px 32px;
    display: flex;
    flex-direction: column;
    width: 100vw;
    position: absolute;
    top: 82.7px;
    background-color: #fff;
    gap: 32px;
    height: 100vh;
    z-index: 1000;
    left: 0;
  }

  .navLink {
    color: var(--camsol-neutral-900);
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
  }

  .logoutButton {
    background-color: var(--platform-secondary-700);
    padding: 16px 24px;
    border-radius: 6px;
    border: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: white;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 140px;
  }

  .imgDivMobile {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: black;
    gap: 12px;
  }
}

@media screen and (max-width: 500px) {
  .navbarLoggedin.shadow {
    box-shadow: none;
  }

  .navbarLoggedin {
    border-bottom: 1px solid #e2e2e2;
  }
}

@media only screen and (max-width: 420px) {
  .inputDivMobile {
    width: 98%;
    min-width: 30px;
  }
}
