.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  align-items: center;
  padding: 64px var(--px);
  justify-content: center;
}

.text__content {
  width: 100%;
  max-width: 921px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  text-align: center;
}

.welcome {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: var(--camsol-primary-600);
}

.title {
  font-weight: 700;
  font-size: 64px;
  line-height: 65px;
  text-transform: capitalize;
  color: #00090d;
  margin-bottom: 0.8rem;
  max-width: 1000px;
}

.lorem {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.8);
}

.bootcamps {
  display: flex;
  align-items: stretch;
  justify-content: center;
  gap: 40px;
}

@media screen and (min-width: 720px) and (max-width: 1020px) {
  .title {
    font-size: 48px;
    line-height: 55px;
  }

  .wrapper .text__content {
    width: 100%;
    /* max-width: 580px; */
  }
}

@media screen and (max-width: 1024px) {
  .bootcamps {
    flex-direction: column;
  }
}

@media screen and (max-width: 900px) {
  .text__content {
    gap: 8px;
  }
  .title {
    width: 65%;
  }
  .lorem {
    font-size: 14px;
    line-height: 16.94px;
  }

  .welcome {
    font-size: 12px;
    line-height: 14.52px;
  }
}

@media screen and (max-width: 768px) {
  .title {
    font-size: 3.5rem;
    padding-right: 0;
    line-height: 40px;
    width: 100%;
  }
}
