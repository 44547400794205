.container {
  width: 100%;
  gap: 1.6rem;
  background: #ffffff;
  padding: 2.4rem;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
}

.head {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: flex-start;
  gap: 5rem;
  width: 95%;
}

.accordion__heading2 {
  margin-top: 2.4rem;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.08em;
  color: #000000;
}

.arrow {
  transform: rotate(0deg);
  transition: 0.2s ease-in-out;
}

.arrow.active {
  transform: rotate(180deg);
}

.resume__learning {
  display: flex;
  justify-content: space-between;
  padding-top: 1.9rem;
  padding-bottom: 1rem;
}

.progressbar {
  width: 15%;
  flex-grow: 2;
}

.btns {
  display: flex;
  gap: 1.6rem;
}

.content {
  height: fit-content;
  padding-bottom: 2.4rem;
}

.customAccordion {
  transition: 0.2s ease-in-out;
  /* min-height: 450px; */
  height: fit-content;
}

.syllabus {
  color: var(--platform-secondary-700);
  border: none;
  background: transparent;
}

.custom {
  /* width: 161px; */
  height: 59px;
  font-size: 16px;
}

.syllabus:hover {
  color: var(--platform-secondary-700);
  background: transparent;
  color: #fff;
}

.load {
  display: flex;
  width: 100%;
  min-height: 5rem;
  height: 100%;
  justify-content: center;
  align-items: center;
}

/* Button:hover {
  background-color: var(--platform-secondary-600);
} */
@media screen and (max-width: 896px) {
  .students__container {
    display: none;
  }
}
@media screen and (max-width: 680px) {
  .instructor__container {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding-inline: 1.5rem;
    width: 95%;
  }
  .custom {
    font-size: 14px;
    height: 40px;
    padding: 10px;
  }
  .head {
    width: 90%;
    gap: 1rem;
  }
  .progressbar {
    width: 35%;
  }
  .progressbar > div > div:first-child > p {
    font-size: 12px !important;
  }
  .progressbar > div > div:first-child > p > span {
    font-size: 12px !important;
  }
}

@media screen and (min-width: 720px) and (max-width: 1020px) {
  .head {
    gap: 0rem;
  }
}
