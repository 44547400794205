.navbar {
  padding: 32px var(--px);
  width: 100%;
  height: 112px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  transition: 0.2s ease-in-out;
}

.navbar.shadow {
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
}

.btn__login {
  color: var(--platform-secondary-700) !important;
  border-color: var(--platform-secondary-700) !important;
  font-weight: 600;
}

.btn__login:hover {
  color: var(--platform-secondary-700) !important;
}

.btn__end {
  font-weight: 500;
}

.btn__end:hover {
  background-color: var(--platform-secondary-600) !important;
}

.navLinkDiv {
  display: flex;
  gap: 24px;
  transition: 0.3s ease-in-out;
  transform: scaleX(1);
}

.navLinkDivInactive {
  display: none;
}

.navLink {
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: var(--camsol-neutral-700);
  position: relative;
  white-space: nowrap;
}

.navLink::before {
  content: '';
  position: absolute;
  width: 100%;
  background-color: var(--platform-secondary-500);
  height: 2px;
  bottom: -1px;
  right: 0;
  transform: scaleX(0);
  transform-origin: right;
  transition: 0.2s ease-in-out;
}

.navLink:hover::before {
  transform: scaleX(0.5);
}

.hiddenButton {
  display: none;
}

.arrow {
  transform: rotate(0deg);
  transition: 0.2s ease-in-out;
  border: none;
  background: #fff;
  margin-left: 11.5px;
}

.arrow.active {
  transform: rotate(-180deg);
}

.arrow:hover {
  background: none !important;
}

.signupBtn {
  font-size: 16px;
  display: inline;
  white-space: nowrap;
  background-color: var(--platform-secondary-700);
}

.signupBtn:hover {
  background-color: var(--platform-secondary-600);
}

.buttonDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.buttonDiv:hover {
  background-color: transparent !important;
}

.inputFieldDiv {
  display: none;
  position: relative;
  width: 0px;
  height: 40px;
  border: none;
  /* transition: 0.3s ease-in-out; */
}

.inputFieldDivActive {
  display: block;
  width: 789px;
  max-width: 80%;
}

.inputFieldDiv input:focus {
  border: none;
}

.inputField {
  padding: 0;
  background: #61012c1a;
  border-radius: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  width: 100%;
  height: 100%;
  border: none;
  color: #00090d;
}

.inputField:focus,
.inputField:active {
  outline: none;
}

.inputFieldDivActive .inputField {
  padding: 10px 12px;
  padding-left: 35px;
}

.iconButton {
  position: absolute;
  border: none;
  cursor: pointer;
  display: none;
  background-color: transparent;
}

.iconButton:hover {
  background-color: transparent !important;
}

.inputFieldDivActive .iconButton {
  display: block;
}

.closeButton {
  right: 14.17px;
  top: 50%;
  transform: translateY(-50%);
}

.closeButton:hover {
  background: none !important;
}

.searchButton {
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  padding-right: 12px;
}

.searchButton:hover {
  background: none !important;
}

.suggestionCardMobile {
  display: none;
}

.suggestionCard {
  position: absolute;
  top: 59px;
  width: 100%;
  background-color: #fff;
  z-index: 10;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  transform: scaleY(0);
  /* transition: 0.1s ease-out; */
  transform-origin: top;
  overflow: hidden;
}

.suggestionCardActive {
  transform: scaleY(1);
}

.suggestedDiv {
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 9px;
}

.suggestedText {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: var(--camsol-neutral-600);
}

.suggested {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #000e14;
}

.allSuggestionsDiv {
  display: flex;
  color: var(--platform-secondary-600);
  padding: 12px;
  gap: 8px;
  width: 100%;
  height: 68px;
  background-color: var(--platform-secondary-50);
  align-items: center;
}

.allSuggestionsDiv p a {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin-left: 12px;
  color: var(--platform-secondary-600);
}

.cardsDiv {
  display: flex;
  height: 358px;
  width: 100%;
  padding: 0px 24px 24px;
  gap: 16px;
}

.cardsDiv .card {
  width: 50%;
}

.mobileNav {
  display: none;
}

.navButton {
  display: flex;
  justify-content: center;
  align-content: center;
  background-color: transparent;
  border: none;
}

.desktopDropDown {
  display: block;
  position: absolute;
}
.mobileDropDown {
  display: none;
}

@media only screen and (max-width: 1350px) {
  .inputFieldDivActive {
    width: 650px;
  }

  .navLink {
    font-size: 14px;
  }

  .navLinkDiv {
    gap: 20px;
    /* outline: 1px solid blue; */
    justify-content: space-around;
    /* padding-right: 16px; */
  }

  .navLinkDiv > div {
    display: none !important;
  }

  .logoDiv {
    width: 120px;
  }

  .logoDiv a,
  .logoDiv img {
    width: 100px !important;
  }
}

@media only screen and (max-width: 1200px) {
  .navLink {
    font-size: 12px;
  }
  .inputField {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1148px) {
  .inputFieldDivActive {
    width: 600px;
    max-width: 60%;
  }
}

@media only screen and (max-width: 1024px) {
  .navbar {
    padding: 32px 79px;
  }

  .navLinkDiv {
    gap: 22.7px;
    /* height: 90vh;
    overflow-y: auto; */
  }

  .navLink {
    font-size: 13px;
  }

  .desktopDropDown {
    display: none;
  }
  .mobileDropDown {
    display: none;
  }

  .mobileDropDownActive {
    display: block;
  }

  .signupBtn {
    font-size: 11.38px;
    padding: 11.4px 17px;
  }

  .arrow {
    margin-left: 8.2px;
  }

  .logo {
    width: 105px;
  }

  .inputFieldDivActive {
    width: 561px;
  }

  .inputField {
    font-size: 11.4px;
  }

  .inputFieldDivActive .inputField {
    padding: 7.1px 8.5px;
    padding-left: 40.5px;
  }

  .closeButton {
    right: 10.07px;
  }

  .searchButton {
    left: 10.07px;
    padding-right: 8.5px;
  }

  .suggestionCard {
    top: 42px;
    border-radius: 11.4px;
    overflow: hidden;
  }

  .suggestedDiv {
    padding: 17px;
    gap: 6.4px;
  }

  .suggestedText {
    font-size: 10px;
    line-height: 12.1px;
  }

  .suggested {
    font-size: 12.8px;
    line-height: 15.6px;
  }

  .allSuggestionsDiv {
    padding: 8.4px;
    gap: 5.6px;
  }

  .allSuggestionsDiv p {
    font-size: 9.8px;
    line-height: 11.9px;
    margin-left: 8.4px;
  }

  .cardsDiv {
    padding: 0px 17.6px 17.6px;
    gap: 11.2px;
  }
}

@media only screen and (max-width: 1020px) {
  .logoDiv,
  .navLinkDiv,
  .buttonDiv {
    display: none;
  }

  .navbar {
    padding: 24px 16px;
    height: 82.74px;
  }

  .mobileNav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .navLinkDivActive {
    display: block;
  }

  .navLinkDivInactive {
    display: none;
  }

  .activeButton {
    display: block;
  }

  .inActiveButton {
    display: none;
  }

  .navLinkDiv {
    position: absolute;
    top: 82.7px;
    display: flex;
    flex-direction: column;
    border-top: 1px solid black;
    width: 100%;
    padding: 24px var(--px);
    gap: 32px;
    background-color: #fff;
    left: 0;
    z-index: 10;
  }

  .navLinkDivInactiveMobile {
    display: none;
  }

  .buttonDivMobileInactive {
    display: none;
  }

  .buttonDivMobileActive {
    display: block;
    width: fit-content;
    height: 48px;
    padding: 16px 24px;
    border-radius: 6px;
    background-color: var(--platform-secondary-700);
    color: white;
    border: none;
    font-size: 14px;
    font-weight: 500;
  }

  .buttonLogin {
    background-color: var(--camsol-white);
    color: var(--camsol-neutral-700);
    border: 1px solid var(--platform-secondary-700);
  }

  .navLink::before {
    width: 0px;
    height: 0px;
  }

  .navLink {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-right: 16px;
  }

  .dropDown {
    position: absolute;
    top: 16px;
  }

  .inputDivMobile {
    min-width: 300px;
    height: 32px;
    width: 70vw;
    position: relative;
  }

  .inputDivMobile input {
    width: 100%;
    background: #61012c1a;
    border-radius: 4px;
    padding: 8px 13px;
  }

  .inputDivMobile input {
    border: none;
    padding-left: 35px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: var(--camsol-neutral-600);
  }

  .inputDivMobile input:focus,
  .inputDivMobile input:active {
    outline: none;
  }

  .inputSearch {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    padding-right: 10px;
  }

  .suggestionCardMobileActive {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 48px;
    background: #ffffff;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }

  .cardsDiv {
    flex-direction: column;
    height: fit-content;
  }

  .cardsDiv .card {
    width: 100%;
  }

  .suggested {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.08em;
    color: #000000;
  }

  .suggestedText {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.02em;
    color: var(--camsol-neutral-600);
  }

  .allSuggestionsDiv {
    padding: 8px;
  }

  .allSuggestionsDiv p {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.02em;
  }

  .btnContainer {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
  }

  .btn__login,
  .loginBtnWrapper {
    display: flex;
  }

  .navLinkDiv > div {
    display: flex !important;
  }

  .navLinkDiv .logoDiv {
    width: unset;
  }

  .logoDiv a,
  .logoDiv img {
    width: unset;
  }
}

.mobileButtons {
  display: none;
  gap: 24px;
}

@media only screen and (max-width: 940px) {
  .navbar {
    padding: 32px 65px;
  }
  .inputFieldDivActive {
    width: 500px;
  }

  .buttonDiv {
    gap: 13px;
  }

  .btn__login,
  .loginBtnWrapper {
    display: none !important;
  }

  .mobileButtons {
    display: flex;
  }
}

@media screen and (max-width: 500px) {
  .navbar.shadow {
    box-shadow: none;
  }

  .navbar {
    border-bottom: 1px solid #e2e2e2;
    gap: 0.5rem;
  }

  .inputDivMobile {
    min-width: 30px;
    height: 32px;
    width: 70vw;
    position: relative;
  }
}
