.top__section {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.title {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.08em;
  color: #00090d;
  margin-bottom: 1.6rem;
  padding-left: 0.5rem;
}

.body {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.9rem;
  margin-bottom: 0.5rem;
}

.header span {
  background-color: var(--platform-secondary-700);
  font-size: 1.2rem;
  font-weight: 600;
  display: grid;
  place-items: center;
  padding: 0rem 0.6rem;
  border-radius: 0.25rem;
  font-variant-numeric: tabular-nums;
  color: var(--camsol-white);
}

.header h6 {
  font-weight: 700;
  font-size: 1.5rem;
}

.topics {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0;
}

.topic > span {
  color: var(--platform-secondary-700);
}

.details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 0px;
  gap: 0rem;
}

.details > p {
  padding: 0;
  line-height: 2rem;
  font-weight: 500;
  font-size: 1.5rem;
}

.details > span {
  color: var(--camsol-neutral-500);
  font-weight: 600;
  font-size: 1.1rem;
}

.customAccordion {
  padding: 1.5rem 1rem;
  border-radius: 0.5rem;
  box-shadow: var(--shadow-md);
  margin: 0.5rem;
}

.course__navigator {
  max-height: 400px;
  height: fit-content;
  overflow-y: auto;
}

.course__navigator::-webkit-scrollbar {
  width: 8px;
}

.course__navigator::-webkit-scrollbar-track {
  background: #fbf8ff;
}

.course__navigator::-webkit-scrollbar-thumb {
  background: var(--platform-secondary-700);
  border-radius: 12px;
  border: 2px solid #fbf8ff;
}

@media screen and (max-width: 500px) {
  .course__navigator {
    height: 100%;
  }
}
