.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 360px;
  margin-bottom: 3.5rem;
  /* height: 254px; */
}

.container h3 {
  margin-bottom: 1.6rem;
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  letter-spacing: 0.08em;
  color: #1c1c1c;
  line-height: 35px;
}

.container span[color='red'] {
  color: var(--platform-secondary-700);
}

.pin__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.field__title {
  margin-bottom: 0.8rem !important;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  text-align: center;
}

.container p {
  /* width: 360px;
  height: 44px; */
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #545454;
}

.container .email {
  margin-bottom: 2.7rem;
  text-align: left;
}

.resend {
  margin-top: 1rem;
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #a9a9a9;
  justify-content: center;
}

.resend a,
.resend h4 {
  text-decoration: none;
  color: var(--platform-secondary-700);
  font-size: 14px;
  margin-left: 0.5rem;
}

.validate__error.validate__error {
  color: rgb(247, 50, 50);
  font-style: italic;
  font-weight: 500;
  margin-top: 0.3rem;
  text-align: left;
  font-size: 1rem;
}

form .instruction {
  display: flex;
  font-weight: 400;
  font-size: 14px;
  /* line-height: 17px; */
  color: #a9a9a9;
  margin-top: -2rem;
  margin-bottom: 2.4rem;
}

.list {
  display: flex;
  display: -ms-flexbox;
  flex-direction: column;
  -ms-flex-direction: column;
  align-items: flex-start;
  list-style-type: none;
  /* gap: 1.2rem; */
  margin-bottom: 1.6rem;
  position: relative;
  /* margin-left: 2.5rem; */
}

.list li {
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #545454;
}

@media screen and (max-width: 900px) {
  .container {
    padding-top: 0;
  }
  .container .email {
    margin-bottom: 2.4rem;
  }
  .container .new {
    line-height: unset;
  }
}
