.label input {
  display: none;
}

.label {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 2.4rem 3.2rem;
  justify-content: space-between;
  gap: 0.8rem;
  cursor: pointer;
  box-shadow: 0px 10px 15px -3px #0000000a;
  background-color: var(--camsol-white);
  border: 2px solid transparent;
  border-radius: 4px;
  transition: border-color 300ms ease;
}

.label:has(.show) {
  border-color: #e60369;
}

.label span {
  display: block;
  font-size: 2rem;
  font-weight: 600;
  line-height: 30px;
  text-transform: capitalize;
}

.label {
  cursor: pointer;
}

.circle {
  aspect-ratio: 1/1;
  width: 2rem;
  border-radius: 100%;
  border: 2px solid var(--platform-secondary-700);
  background-color: transparent;
  position: relative;
  pointer-events: none;
}

.dot {
  aspect-ratio: 1/1;
  width: 1rem;
  border-radius: 100%;
  background-color: var(--platform-secondary-700);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: transform 300ms ease;
  transform-origin: left;
}

.show {
  transform: scale(1) translate(-50%, -50%);
}

.hide {
  transform: scale(0) translate(-50%, -50%);
}
