.wrapper {
  width: 100%;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: center;
}

.meetInstructor {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px var(--px);
  gap: 24px;
  max-width: 1440px;
  background: #ffffff;
  justify-content: space-evenly;
  overflow: hidden;
}

.title {
  height: 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: var(--platform-secondary-700);
}

.slideInfinite {
  display: inline-flex;
}

.name {
  width: 1023px;
  left: 209px;
  top: 10px;
  margin-inline: 45px;
  font-style: normal;
  font-weight: 700;
  font-size: 96px;
  line-height: 65px;
  text-align: center;
  color: #00090d;
  animation: scroll 20s linear infinite;
  padding-block: 15px;
}

@keyframes scroll {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-1023px, 0, 0);
  }
}

.profession {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  letter-spacing: 0.08em;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 4.8rem;
}

.video {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 16px;
  position: relative;
  aspect-ratio: 16/9;
  width: 100rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.video:before {
  /* content: ''; */
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 16px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.video video {
  width: 100%;
  aspect-ratio: 16/9;
  object-fit: cover;
  border-radius: 16px;
}

.video span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--camsol-neutral-50);
  cursor: pointer;
  transition: all 300ms ease;
}

.video span:hover {
  scale: 1.1;
}

.video span:active {
  scale: 0.9;
}

.hide {
  opacity: 0;
}

.video:hover .hide {
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .meetInstructor {
    gap: 0px;
  }

  .title,
  .profession {
    font-size: 14px;
  }

  .name {
    font-size: 32px;
    color: var(--camsol-neutral-900);
    animation-duration: 6000ms;
  }

  .profession {
    font-weight: 400;
  }

  .video {
    aspect-ratio: 16/10;
    width: 100%;
    margin-top: 1rem;
  }

  .video video {
    width: 100%;
    aspect-ratio: 16/10;
  }
}

@media (prefers-reduced-motion) {
  .name {
    animation: none;
  }
}
