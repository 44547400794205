.body {
  padding: 3.2rem;
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
  margin-bottom: 1rem;
  background: var(--camsol-white);
  border: 1px solid #0000000a;
  box-shadow: 2px 4px 19.3px 0px #0000000d;
  border-radius: 16px;
  position: relative;
}

.title {
  font-size: 28px;
  font-weight: 600;
}

.question {
  margin-bottom: 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  align-items: flex-start;
}

.question .text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.8rem;
}

.question .text > span {
  font-size: 2rem;
  font-weight: 600;
  line-height: 30px;
  opacity: 0.3;
}

.question .text > h6 {
  font-size: 2rem;
  font-weight: 600;
  line-height: 30px;
  text-wrap: balance;
}

.divider {
  width: 100%;
  border: 1px solid #e8e8e8;
}

.buttons {
  display: flex;
  justify-content: space-between;
  row-gap: 1rem;
  width: 100%;
  flex-wrap: wrap;
}

.btn {
  padding: 1.6rem 2.4rem;
  font-size: 1.5rem;
  height: fit-content;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 19.36px;
}

@media screen and (max-width: 500px) {
  .btn {
    width: 100%;
  }
}

.discard {
  color: var(--platform-secondary-600) !important;
  border-color: var(--platform-secondary-600) !important;
}

.progressBody {
  padding: 2.4rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  align-items: flex-start;
  background-color: var(--camsol-primary-50);
  border-radius: 0.8rem;
  position: sticky;
  top: 12rem;
  z-index: 10;
}

.progressBody > h3 {
  font-size: 2rem;
  font-weight: 600;
  line-height: 30px;
}
