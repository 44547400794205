.container {
  display: flex;
  width: 100%;
  gap: 32px;
  padding: 32px;
  padding-top: 15rem;
}

.container .details_title {
  max-width: 300px !important;
}

.heading1 {
  font-weight: 800;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: 0.08em;
  color: #000000;
  margin-bottom: 2.4rem;
}

.my__courses {
  width: 70%;
}

.suggested__courses {
  width: 30%;
  height: 100%;
}

.heading2 {
  font-weight: 800;
  font-size: 32px;
  line-height: 39px;
  letter-spacing: 0.08em;
  color: #000000;
  margin-bottom: 2.4rem;
}

.suggestions {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  flex-wrap: wrap;
}

.loading__state {
  height: 160px;
  border-radius: 16px;
  background: rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
}

.loading__state::before {
  content: '';
  position: absolute;
  height: 100%;
  box-shadow: 0 0 500px 80px rgba(0, 0, 0, 0.1);
  animation: move 1.3s linear infinite;
}

@keyframes move {
  from {
    left: 0;
  }
  to {
    left: 100%;
  }
}

@-webkit-keyframes move {
  from {
    left: 0;
  }
  to {
    left: 100%;
  }
}

@media screen and (max-width: 1350px) {
  .container {
    flex-direction: column;
  }

  .my__courses,
  .suggested__courses {
    width: 100%;
  }

  .suggestions {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  }

  .my__courses__container {
    flex-direction: column;
  }
}

@media screen and (max-width: 620px) {
  .container {
    padding: 32px 3.2rem;
    padding-top: 15rem;
  }

  .suggestions {
    grid-template-columns: 1fr;
    width: 100%;
    display: flex;
  }
}
