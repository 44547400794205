.input {
  width: 360px;
  height: 50px;
  border: 1.5px solid #c5c5c5;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  font-size: 13px;
  gap: 10px;
  background: #ffffff;
}

.input::placeholder {
  color: var(--camsol-neutral-300);
}

.input:hover {
  border: 1.5px solid var(--platform-secondary-700);
}
.input:focus {
  outline: none;
  border: 1.5px solid var(--platform-secondary-700);
}

.input.left {
  padding-left: 4rem;
}

.input.right {
  padding-right: 4rem;
}

.container {
  /* margin-bottom: 24px;
  display: flex;
  align-items: center; */
  display: flex;
  display: -ms-flexbox;
  gap: 1rem;
  /* margin-bottom: 2.4rem;
  margin-top: 0.8rem; */
  position: relative;
  align-items: center;
}

.container .iconLeft {
  position: absolute;
  left: 1.5rem;
}

.container .iconRight {
  position: absolute;
  right: 1.5rem;
}
