.body {
  background: var(--camsol-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.body > h1 {
  font-size: 4rem;
  font-weight: 800;
  color: var(--platform-secondary-700);
  text-align: center;
  letter-spacing: 3.2px;
}

.body > p {
  color: rgba(0, 0, 0, 0.4);
  font-size: 2rem;
  text-align: center;
  font-weight: 400;
}

.body > video {
  max-width: 25%;
}

.footer p {
  color: var(--camsol-neutral-800, #1c1c1c);
  font-size: 1.2rem;
  font-weight: 400;
  letter-spacing: 0.24px;
  margin-block: 32px;
}

@media screen and (max-width: 620px) {
  .body > h1 {
    font-size: 38px;
  }

  .body > p {
    font-size: 16px;
  }

  .body > video {
    max-width: 70%;
  }
}
