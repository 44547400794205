.filter {
  margin-right: 32px;
}

.hide {
  display: none;
}

.show {
  display: block;
}

.filter__block {
  border: 1px solid #e2e2e2;
  border-bottom: none;
  border-right: none;
  border-left: none;
  padding: 24px 16px;
}

.head h2 {
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 2.9rem;
  color: var(--camsol-black);
}

.content {
  margin-top: 2.4rem;
}

.option {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  gap: 0.8rem;
}

.option.space {
  margin-bottom: 1.7rem;
}

.option.sub {
  margin-top: 1.6rem;
  margin-left: 2.4rem;
}

.option h4 {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: var(--camsol-black);
}

.option h4 span {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  gap: 0.8rem;
  cursor: pointer;
}
