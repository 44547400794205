.crumb {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
}

.crumb a {
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 15px;
  color: var(--camsol-neutral-400);
  text-decoration: none;
  white-space: nowrap;
}

.crumb a:not(:first-of-type) {
  margin-left: 0.5rem;
}

.crumb a.active {
  color: var(--platform-secondary-700);
}

@media screen and (max-width: 500px) {
  .crumb a {
    font-size: 8px;
  }
}
