.body {
  padding: 0px;
  width: 100%;
}

.container {
  padding: 48px 32px;
  width: 100%;
}

.upperHalf {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.logoDiv {
  width: 308px;
  color: var(--camsol-neutral-600);
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  margin-right: 78px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}

.controlDiv {
  display: flex;
  align-items: center;
  /* justify-content:space-between; */
  flex-wrap: wrap;
  /* gap: 90px; */
  padding: 0px;
  /* margin-right: 78px; */
}

.controlDiv > .link {
  color: var(--camsol-neutral-600);
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  white-space: nowrap;
  padding: 0px;
  margin-right: 30px;
  width: 120px;
}

.emailDiv {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.emailDiv > p {
  font-size: 14px;
  line-height: 17px;
  color: black;
}

.inputDiv {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  gap: 8px;
  width: 100%;
}

.btn {
  font-weight: 500;
}

.btn:hover {
  font-weight: 500;
  background-color: var(--platform-secondary-600) !important;
}

.lowerHalf {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.social {
  display: flex;
  gap: 18px;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  line-height: 19px;
  font-weight: 500;
  color: var(--camsol-neutral-600);
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
}

.social > span {
  font-weight: 700;
  color: var(--camsol-neutral-800);
}

.social > a {
  color: var(--camsol-neutral-600);
}

.copyright {
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 15px;
  color: var(--camsol-neutral-800);
}

@media screen and (max-width: 768px) {
  .container {
    display: flex;
    flex-direction: column;
  }

  .upperHalf {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .logoDiv {
    width: 100%;
  }

  .logoDiv > p {
    margin-top: 8px;
  }

  .controlDiv {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem 1.5rem;
  }

  .lowerHalf {
    flex-direction: column;
    gap: 24px;
  }

  .copyright {
    text-align: center;
  }

  .social span {
    display: none;
  }

  .social {
    gap: 8px;
    font-size: 12px;
    line-height: 14px;
    justify-content: center;
    padding-bottom: 10px;
    row-gap: 12px;
  }
}
