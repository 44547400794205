.course__card {
  width: 100%;
  /* max-width: 319px; */
}

.course__card .image {
  word-wrap: 100%;
  overflow: hidden;
  overflow: -moz-hidden-unscrollable;
  border-radius: 8px;
  height: 405px;
  position: relative;
  background: rgba(0, 0, 0, 0.1);
}

.overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 2.6rem 2.4rem;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}

.course__card .image:hover .overlay,
.course__card .image:focus-within .overlay {
  opacity: 1;
}

.overlay h3,
.overlay p {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #fff;
}

.overlay h3 {
  margin-bottom: 0.7rem;
  display: flex;
  display: -ms-flexbox;
  gap: 4px;
}

.overlay .ref__link {
  width: 100%;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: center;
}

.overlay .ref__link p {
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: #fff;
  text-decoration: underline !important;
  text-align: center;
  margin-top: 98.5px;
}

.course__card .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
}

.content {
  padding: 1.6rem 0;
  padding-bottom: 0;
}

.shadow {
  margin: 1.6rem 0;
  background: rgba(0, 0, 0, 0.1);
  width: 70%;
  height: 80px;
}

.course__card.light .shadow,
.course__card.light .image {
  background: rgba(255, 255, 255, 0.7);
  height: 50px;
}

.course__card.light .image {
  height: 226px;
}

.content h2 {
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 150%;
  color: var(--camsol-black);
  margin-bottom: 0.8rem;
}

.instructor {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  gap: 1.2rem;
  margin-bottom: 1.05rem;
}

.instructor .pic {
  width: 20px;
  height: 20px;
  overflow: hidden;
  overflow: -moz-hidden-unscrollable;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.instructor .pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
}

.content h3 {
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: var(--camsol-neutral-700);
}

.content h3 span {
  font-weight: 600;
}

.more__info {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  gap: 1.6rem;
}

.more__info .duration,
.more__info .lessons,
.more__info .lang__globe {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  gap: 0.4rem;
}

.more__info h4 {
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: var(--camsol-neutral-700);
}

.languages {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
}
