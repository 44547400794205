.body {
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
  padding-top: 13rem;
}

.body > section {
  padding: 3rem;
  outline: none;
  /* min-height: 50vh; */
}

.top {
  outline: none !important;
  display: flex;
  align-items: flex-start;
  gap: 2.5rem;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .top {
    flex-direction: column;
  }
}
