.wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 var(--px);
  justify-content: center;
}

.wrapper .text__content {
  max-width: calc(100vw - var(--px));
  width: 100%;
}

.rootContain {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  padding: 64px 0;
  background: #ffffff;
}

.welcome {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: var(--camsol-primary-600);
  margin-bottom: 1.6rem;
}

.title {
  font-weight: 700;
  font-size: 64px;
  line-height: 65px;
  text-transform: capitalize;
  color: #00090d;
  margin-bottom: 2.4rem;
  max-width: 1000px;
}

.lorem {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 6.4rem;
  max-width: 950px;
}

.slider {
  width: 100%;
  margin: auto;
  position: relative;
  overflow-x: hidden;
}

.slider::-webkit-scrollbar {
  display: none;
}

@media screen and (min-width: 1921px) {
  .slider {
    min-width: 95vw;
    transform: translateX(-250px);
  }
}

.sliderTrack {
  width: calc(200px * 12);
  animation: scroll 10s linear infinite;
  /* overflow: auto; */
}

.sliderTrack:hover {
  animation-play-state: paused;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-200px * 3));
  }
}

.video {
  height: 340px;
  border-radius: 16px;
  transition: transform 2s;
}

@media screen and (min-width: 720px) and (max-width: 1020px) {
  .title {
    font-size: 48px;
    line-height: 55px;
  }

  .wrapper .text__content {
    width: 100%;
    /* max-width: 580px; */
  }
}

@media screen and (max-width: 900px) {
  .slider {
    margin-top: 6.4rem;
  }
  .title {
    width: 65%;
  }
  .lorem {
    margin-bottom: 1.6rem;
  }
  .slider {
    margin-top: 0;
  }
  .welcome {
    margin-bottom: 0.8rem;
  }
}
@media screen and (max-width: 850px) {
  .lorem {
    font-size: 14px;
    line-height: 16.94px;
  }

  .welcome {
    font-size: 12px;
    line-height: 14.52px;
  }
}

@media screen and (max-width: 768px) {
  .title {
    font-size: 3.5rem;
    padding-right: 0;
    line-height: 40px;
    width: 100%;
  }
}
