.container {
  width: 100%;
}

.carousel__wrapper {
  cursor: grab;
  width: 100%;
  overflow: auto;
  overflow: auto;
}

.carousel__wrapper::-webkit-scrollbar {
  display: none;
}

.inner__carousel {
  display: flex;
  display: -ms-flexbox;
  width: 100%;
  gap: 0.5rem;
  padding: 3rem 0;
}

.inner__carousel.p {
  padding: 0;
}

.carousel__item {
  /* min-height: 20rem; */
  min-width: 20rem;
  /* padding-right: 1rem; */
  display: flex;
  display: -ms-flexbox;
  flex-direction: column;
  -ms-flex-direction: column;
}

.carousel__item img {
  pointer-events: none;
}
