.checkbox__wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.2rem;
  margin-bottom: 1rem;
}

.label {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 2.4rem 3.2rem;
  justify-content: space-between;
  gap: 0.8rem;
  box-shadow: 0px 10px 15px -3px #0000000a;
  background-color: var(--camsol-white);
  border-radius: 4px;
  transition: border-color 300ms ease;
}

.label:has(input:checked) {
  background-color: var(--platform-secondary-600);
}

.label:has(input:not(:checked)) * {
  opacity: 0.3;
}

.label input {
  accent-color: var(--platform-secondary-600);
  width: 1.5rem;
  height: 1.5rem;
}

.label:has(input:checked) input {
  accent-color: var(--camsol-white);
}

.label span {
  display: block;
  font-size: 2rem;
  font-weight: 600;
  line-height: 30px;
  text-transform: capitalize;
}

.label:has(input:checked) span {
  color: var(--camsol-white);
}

.correct,
.wrong {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem 3.2rem;
  box-shadow: 0px 10px 15px -3px #0000000a;
  border-radius: 4px;
  width: 100%;
}

.correct {
  border-left: 4px solid var(--camsol-status-success);
  --span-color: var(--camsol-status-success);
}

.wrong {
  border-left: 4px solid var(--camsol-status-error);
  --span-color: var(--camsol-status-error);
}

.title {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.title > h6 {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 24px;
  color: var(--span-color);
}

.text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}

.text > p {
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 24px;
  text-wrap: balance;
}

.text:has(p:empty) {
  display: none;
}
