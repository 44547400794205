.body {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background-color: rgba(5, 23, 31, 0.3);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  transition: opacity 300ms ease-in-out;
}

.open {
  opacity: 1;
  pointer-events: all;
  user-select: all;
}

.closed {
  opacity: 0;
  pointer-events: none;
  user-select: none;
}
