.body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  gap: 3rem;
  padding: var(--py) var(--px);
}

.title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}

.title > p {
  font-size: 2rem;
  font-weight: 400;
}

.title > h1 {
  font-weight: 700;
  font-size: 5rem;
}

.courses {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 3.2rem;
  padding: 2.5rem 0rem;
  padding-bottom: 4rem;
  margin-bottom: -3.5rem;
  overflow-x: auto;
}

.courses::-webkit-scrollbar {
  display: none;
}

.cardDiv {
  /* flex-shrink: 0; */
  min-width: 278px;
  height: 446px;
}

@media screen and (max-width: 768px) {
  .title > p {
    font-size: 12px;
    line-height: 14px;
  }

  .title > h1 {
    font-size: 32px;
    line-height: 38px;
  }
}
