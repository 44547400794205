.wrapper {
  width: 100%;
  padding: 0 var(--px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.body {
  max-width: calc(100vw - var(--px));
  width: 100%;
  display: flex;
  gap: 2rem;
  justify-content: center;
  align-items: center;
}

.body > div {
  width: 50%;
  /* outline: 1px solid blue; */
}

.text {
  padding-right: 5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.text > h1 {
  font-weight: 700;
  padding-right: 4rem;
  font-size: 6.4rem;
  line-height: 65px;
}

.text > p {
  font-size: 1.5rem;
}

.instructors {
  height: 41rem;
  background: url('../../../../repository/assets/images/bg.png');
  background-position: left top;
  background-repeat: no-repeat;
  position: relative;
  /* isolation: isolate; */
}

.ellipses {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dot {
  aspect-ratio: 1/1;
  width: 0.7rem;
  border-radius: 100%;
  position: absolute;
  z-index: 2;
}

.icon {
  position: absolute;
}

.instructor {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  padding: 0.75rem;
  position: absolute;
  animation: faderev 500ms reverse ease;
  z-index: 5;
  isolation: isolate;
}

.instructor .content {
  display: none;
  color: var(--camsol-white);
}

.content > h3 {
  font-weight: 800;
  font-size: 1.5rem;
}

.content > p {
  font-size: 1.1rem;
}

.instructor .avatar {
  aspect-ratio: 1/1;
  width: var(--w, 6rem);
  object-fit: cover;
  border-radius: 100%;
}

.instructor:hover .avatar {
  border: 2px solid var(--camsol-white);
}

.instructor:hover .content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: flex-start;
}

.instructor:hover {
  background-color: var(--camsol-primary-900);
  box-shadow: var(--shadow-sm);
  border-radius: 4rem;
  min-width: 20rem;
  z-index: 10;
  animation: fade 800ms ease;
}

.instructor:hover span {
  display: none;
}

.instructor span {
  aspect-ratio: 1/1;
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  border-radius: 100%;
  background-color: var(--camsol-primary-100);
  animation: pulse infinite linear 2500ms;
  z-index: -1;
  pointer-events: none;
  user-select: none;
}

.instructor .third {
  animation-delay: 0ms;
  opacity: 0.4;
  /* filter: blur(1px); */
  /* z-index: -3; */
}

.instructor .second {
  animation-delay: 500ms;
  opacity: 0.6;
  /* filter: blur(1px); */

  /* z-index: -2; */
}

.instructor .first {
  animation-delay: 1000ms;
  opacity: 0.8;
  /* filter: blur(1.5px); */
}

.custom:hover {
  background-color: var(--platform-secondary-600) !important;
}

@keyframes pulse {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1.8);
    opacity: 0;
  }
}

@keyframes fade {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}

@keyframes faderev {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.2;
  }
}

@media screen and (min-width: 720px) and (max-width: 1020px) {
  .text > h1 {
    font-size: 48px;
    line-height: 55px;
  }
  .text > p {
    width: 70%;
  }
  .body {
    flex-direction: column;
    position: relative;
    margin-bottom: 6rem;
  }
  .body > div {
    width: 100%;
  }
  .body > div:last-child {
    max-width: 580px;
    margin: auto;
  }
  .text {
    padding-right: 0;
    display: flex;
  }
}

@media screen and (max-width: 768px) {
  .body {
    flex-direction: column;
    position: relative;
    margin-bottom: 6rem;
  }

  .body > div {
    width: 100%;
  }
  .text {
    padding-right: 0;
    display: flex;
  }

  .text > h1 {
    font-size: 3.5rem;
    padding-right: 0;
    line-height: 40px;
  }

  /* .custom {
    position: absolute;
    bottom: -4.5rem;
  } */
}

@media screen and (max-width: 450px) {
}
