.logo__styles {
  padding: 4rem 3.7rem;
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
}

.link a {
  display: flex;
  text-decoration: none;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  text-align: center;
  letter-spacing: 0.02em;
  color: #1c1c1c;
}

.link p {
  padding-left: 1.2rem;
}

.link:hover {
  padding: 8px;
  border: 2px solid #00090d;
  border-radius: 8px;
}

@media screen and (max-width: 900px) {
  .logo__styles {
    display: flex;
    /* align-items: center; */
    margin-top: 4.8rem;
    margin-bottom: 2.8rem;
    justify-content: center;
    padding: 0;
  }
}
