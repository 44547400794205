.course__category {
  width: 100%;
  display: flex;
  display: -ms-flexbox;
  flex-direction: column;
  -ms-flex-direction: column;
  padding-top: 13rem;
}

.course__category .head h1 {
  font-weight: 700;
  font-size: 6.4rem;
  line-height: 6.8rem;
  text-align: center;
  letter-spacing: 0.08em;
  color: #00090d;
  margin-bottom: 1.6rem;
}

.course__category .head h2 {
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.2rem;
  text-align: center;
  color: #000000;
  margin-bottom: 2.4rem;
}

.categories {
  margin-bottom: 2.4rem;
  gap: 1.6rem;
  /* width: 1208px; */
  width: 100%;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  padding: 0 var(--px);
}

.categories,
.categories .category {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: center;
}

.categories .category {
  padding: 16px 32px;
  gap: 4px;
  height: 56px;
  border: 2px solid var(--platform-secondary-700);
  background: #fff;
  border-radius: 32px;
  cursor: pointer;
}

.categories .category.active {
  background: var(--platform-secondary-700);
}

.categories .category h3 {
  font-weight: 800;
  font-size: 2rem;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.08em;
  color: var(--platform-secondary-700);
  white-space: nowrap;
}

.categories .category.active h3 {
  color: #fff;
}

.all__courses {
  width: 100%;
}

.all__courses .head h2 {
  font-weight: 700;
  font-size: 3rem;
  line-height: 3.8rem;
  color: var(--camsol-black);
  margin-bottom: 1.6rem;
  text-align: left;
}

.all__courses .head h3 {
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: var(--camsol-black);
  margin-bottom: 2.4rem;
}

.courses__gallery {
  padding-bottom: 84px;
  width: 100%;
  padding-left: var(--px);
}
.all__courses .content {
  padding: 0 var(--px);
}

@media screen and (max-width: 1230px) {
  .categories {
    width: 100%;
  }
}

@media screen and (max-width: 1020px) {
  .course__category .head > h1 {
    font-size: 4.8rem;
    line-height: 3rem;
  }

  .course__category {
    padding-top: 12rem;
  }
}

@media screen and (max-width: 768px) {
  .course__category .head > h1 {
    font-size: 3rem;
    line-height: 2.5rem;
  }
  .course__category {
    padding-top: 10rem;
  }

  .categories .category {
    padding: 8px 16px;
    gap: 4px;
    height: fit-content;
  }

  .categories .category h3 {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: unset;
  }
}
