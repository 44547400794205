.wrapper {
  width: 100%;
  padding: 0 var(--px);
  display: flex;
  align-items: center;
  justify-content: center;
  /* outline: 1px solid orange; */
}

.heading {
  font-family: var(--inter-font);
  font-weight: 700;
  font-size: 64px;
  line-height: 65px;
  text-align: center;
  margin: 24px 0;
  margin-top: 64px;
  width: 1008px;
}

.div__styles {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.p__styles {
  font-family: var(--inter-font);
  font-weight: 400;
  font-size: 20px;
  line-height: 24.2px;
  text-align: center;
}

.cont {
  height: 890px;
  position: relative;
  width: 100%;
}

.pagination__wrapper {
  display: none;
}

.reviews__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-top: 5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 890px;
  overflow-y: auto;
  width: 100%;
  margin-top: 4rem;
  pointer-events: none;
}

.shadow {
  position: absolute;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0.9) 42%,
    rgba(255, 255, 255, 1) 100%
  );
  width: 100%;
  height: 20%;
  bottom: 0;
  z-index: 99;
}

.shadowTop {
  transform: rotate(180deg);
  top: -1px;
}

.reviews {
  column-count: 3;
  column-gap: 32px;
  height: auto;
  /* position: absolute; */
  top: 3rem;
  padding-top: 50rem;
}

.reviews__wrapper::-webkit-scrollbar {
  display: none;
  width: 0;
}

.reviews > div {
  page-break-inside: avoid;
  break-inside: avoid-column;
}

@media screen and (max-width: 1133px) {
  .reviews {
    column-count: 2;
  }
  .pagination__wrapper {
    display: none;
  }
}

@media screen and (max-width: 1100px) {
  .heading {
    width: 100%;
  }
  .pagination__wrapper {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .reviews {
    column-count: 1;
  }
  .pagination__wrapper {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .heading {
    font-size: 28px;
    line-height: 33.89px;
  }
  .p__styles {
    font-size: 14px;
    line-height: 16.94px;
  }
  .pagination__wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
  }
}
