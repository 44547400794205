.body {
  width: 100%;
  /* outline: 1px solid yellow; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  overflow-x: hidden;
  padding-bottom: 2.5rem;
}

.title {
  font-weight: 800;
  font-size: 3.2rem;
}

.tabContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.tab {
  cursor: pointer;
  font-weight: 400;
  color: var(--camsol-neutral-400);
  padding-inline: 3.5rem;
  padding-bottom: 1.8rem;
  border-bottom: 2px solid transparent;
  transition: all 300ms ease;
  font-size: 18px;
}

.tab:hover {
  color: var(--camsol-neutral-900);
}

.tabActive {
  font-size: 1.7rem;
  color: var(--camsol-neutral-900);
  padding-bottom: 1.3rem;
  border-color: var(--camsol-neutral-900);
}

.courses {
  width: 100%;
  display: flex;
  overflow-x: scroll;
  padding: 0.5rem 1rem;
  padding-bottom: 4rem;
  gap: 1.5rem;
}

.courses::-webkit-scrollbar {
  display: none;
}

.learningHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.customBtn {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  text-align: center;
  letter-spacing: 0.02em;
  text-decoration-line: underline;
  color: var(--platform-secondary-800);
  background: transparent;
  border: 2px solid transparent;
  padding: 4px 6px;
  height: fit-content;
}

.customBtn:hover {
  color: var(--platform-secondary-700) !important;
  border: 2px solid var(--platform-secondary-700) !important;
  background: none !important;
}

.learning {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-height: 65vh;
  padding: 1.2rem;
  padding-bottom: 1.6rem;
  overflow: hidden;
  overflow-y: auto;
}

.learning::-webkit-scrollbar {
  width: 10px;
  cursor: pointer;
  border-radius: 4px;
}

.learning::-webkit-scrollbar-track {
  border-radius: 4px;
  background: var(--camsol-primary-100);
}

.learning::-webkit-scrollbar-thumb {
  background: var(--platform-secondary-200);
  border-radius: 4px;
  transition: all 150ms ease;
}

.learning::-webkit-scrollbar-thumb:hover {
  background: var(--platform-secondary-300);
}

.loading__state {
  width: 100%;
  height: 130px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 16px;
}

@media screen and (max-width: 768px) {
  .body {
    width: 100%;
  }
  .title {
    font-size: 28px;
  }
  .tab {
    font-size: 12px;
    padding-inline: 16px;
    padding-bottom: 12px;
  }
  .tabActive {
    font-size: 14px;
    padding-bottom: 9px;
  }
  .learningHeader {
    margin-top: -2rem;
  }
  .learning {
    padding-inline: 1rem;
    max-height: 30vh;
  }
  .learning::-webkit-scrollbar {
    width: 5px;
  }
}
