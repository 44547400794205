.card {
  position: relative;
  border-radius: 16px;
  height: 100%;
  width: 100%;
  flex-shrink: 0;
}

.card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.37) 0.01%,
    rgba(0, 0, 0, 0.9) 84.54%
  );
}

.image {
  height: 100%;
  width: 100%;
  border-radius: 16px;
  object-fit: cover;
}

.content {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  color: var(--camsol-primary-50);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 32px 16px;
}

.title {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.08em;
  color: #ebeff1;
  margin-bottom: 10px;
}

.desc {
  max-height: 50px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  overflow: hidden;

  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 10px;
}

.timespan {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.timespan span {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 5px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}
