.wrapper {
  width: 100%;
  padding: 6.4rem var(--px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.students {
  gap: 48px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.image {
  width: 50%;
  max-width: 571px;
  object-fit: contain;
}

.texts {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.texts > * {
  max-width: 600px;
}

.sub__text {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #e60369;
  margin-bottom: 16px;
}

.headline {
  font-weight: 700;
  font-size: 64px;
  line-height: 65px;
  color: #00090d;
  margin-bottom: 24px;
}

.desc {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 24px;
}

.bouton:hover {
  background-color: var(--platform-secondary-600) !important;
}

.btn {
  text-decoration: none;
}

@media screen and (max-width: 1350px) {
  .students {
    max-width: 100%;
    width: 100%;
  }
}

@media screen and (max-width: 850px) {
  .students {
    flex-direction: column-reverse;
  }

  .image {
    width: 100%;
  }

  .texts {
    width: 100%;
  }

  .sub__text {
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 8px;
  }

  .headline {
    font-size: 32px;
    line-height: 39px;
    margin-bottom: 16px;
  }

  .desc {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 16px;
  }
}

@media screen and (max-width: 500px) {
  .wrapper {
    padding: 2.4rem var(--px);
  }
}
