.wrapper {
  width: 100%;
  padding: 6.4rem var(--px);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
}

.courses {
  width: 1216px;
  padding: 48px 64px;
  gap: 34px;
  background: #000000;
  border-radius: 32px;
  /* min-height: 827px; */
}

.courses > h2 {
  font-weight: 700;
  font-size: 64px;
  line-height: 65px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 3.4rem;
}

.courses {
  max-width: calc(100vw - var(--px));
  width: 100%;
  padding: 48px 64px;
  gap: 34px;
  background: #000000;
  border-radius: 32px;
  /* min-height: 827px; */
}

.btn__styles {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

.tabs__container {
  display: flex;
  display: -ms-flexbox;
  justify-content: center;
}

.avoidance {
  max-width: 800px;
}

.tab {
  font-weight: 400;
  color: #fff;
  font-size: 1.4rem;
  padding-bottom: 1.8rem;
  border-bottom: 2px solid transparent;
  transition: all 300ms ease;
  white-space: nowrap;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tab:hover {
  color: #fff;
}

.tabActive {
  color: #fff;
  border-color: #fff;
}

.enroll__btn {
  background: transparent;
  border: 2px solid #ffffff;
}

.enroll__btn:hover {
  background: none !important;
  border: 2px solid var(--platform-secondary-600) !important;
  color: var(--platform-secondary-600) !important;
}

.empty {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-block: 6rem;
  gap: 1rem;
}

.empty > h2 {
  font-weight: 600;
  font-size: 2rem;
  color: var(--camsol-white);
}

@media screen and (max-width: 1350px) {
  .courses {
    max-width: 100%;
    width: 100%;
  }

  .courses > h2 {
    font-size: 54px;
    line-height: 60px;
  }
}

@media screen and (max-width: 1133px) {
  .courses > h2 {
    font-size: 45px;
    line-height: 55px;
  }

  .courses {
    padding: 48px 40px;
  }
}

@media screen and (min-width: 720px) and (max-width: 1020px) {
  .courses > h2 {
    font-size: 48px;
  }
}

@media screen and (max-width: 900px) {
  .courses > h2 {
    font-size: 40px;
    line-height: 50px;
  }

  .courses {
    padding: 48px 35px;
  }

  .tabContainer,
  .tabs__container {
    justify-content: flex-start;
  }

  .tabs__container {
    margin-bottom: 1.8rem;
  }
}

@media screen and (max-width: 500px) {
  .courses > h2 {
    font-size: 32px;
    line-height: 40px;
  }

  .courses {
    padding: 48px 32px;
  }

  .wrapper {
    padding: 2.4rem 6vw;
  }
}

@media screen and (max-width: 390px) {
  .courses {
    padding: 48px 16px;
  }
}
