.rootContenaire {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--px);
  gap: 10px;
  width: 100%;
  /* min-height: 810px; */
  background: #ffffff;
  margin-top: 64px;
}

.rootGrid {
  display: grid;
  max-width: 1440px;
  gap: 24px;
  grid-template-columns: 1fr 1fr 1fr;
  background-color: #f4f4f4;
  padding: 64px 3.2rem;
  border-radius: 32px;
}

.peek {
  font-weight: 800;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: 0.08em;
  color: #000000;
}

Button:hover {
  background-color: var(--platform-secondary-600);
}

.description {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #383838;
  margin: 2.4rem 0;
  margin-bottom: 1.5rem;
}

.gridItem {
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
}

.button:hover {
  background-color: var(--platform-secondary-600) !important;
}

.textButton {
  width: 115px;
  height: 19px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  color: #ffffff;
}

.gridItem2 {
  display: flex;
  flex-direction: column;
  /* justify-content: space-evenly; */
  gap: 1.6rem;
  align-items: flex-start;
}

.gridItem3,
.gridItem3 > div {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  /* justify-content: space-evenly; */
  align-items: flex-start;
}

.course {
  font-weight: 700;
  font-size: 24px;
  letter-spacing: 0.08em;
  color: #000000;
}

.textIcon {
  display: inline-flex;
  justify-content: space-between;
}

.Vector {
  height: 20px;
  width: 19px;
  left: 2.5px;
  top: 2px;
  border-radius: 0px;
}

.category {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  color: #383838;
  margin-inline: 5px;
}

.lang {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  color: #383838;
  margin-inline: 5px;
}

.youWillLearn {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  margin-top: 0.8rem;
  letter-spacing: 0.08em;
  color: #000000;
}

.tick1 {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  letter-spacing: 0.02em;
  color: #000000;
  margin-inline: 5px;
}

.tick2 {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  color: #000000;
  margin-inline: 5px;
}

.requirements {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.08em;
  color: #000000;
}

.describe {
  font-size: 18px;
  line-height: 22px;
  text-align: justify;
  color: #383838;
}

.courseDescription {
  font-weight: 700;
  font-size: 24px;
  margin-top: 0.8rem;
  line-height: 29px;
  letter-spacing: 0.08em;
  color: #000000;
}

.inThisCourse {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: justify;
  color: #383838;
}

@media screen and (max-width: 1100px) {
  .rootGrid {
    grid-template-columns: 1fr;
  }
}
