.module {
  padding: 24px;
  gap: 24px;
  height: fit-content;
  background: #ffffff;
  box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
}

.module .head h2 {
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 29px;
  letter-spacing: 0.08em;
  color: #1c1c1c;
  margin-bottom: 0.8rem;
}

.module .head h3 {
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 22px;
  color: #545454;
  margin-bottom: 2.4rem;
}

.form__control {
  display: flex;
  display: -ms-flexbox;
  gap: 2.4rem;
  align-items: center;
  width: 100%;
  margin-bottom: 2.4rem;
}

.form__control .input__wrapper {
  width: 100%;
}

.label {
  margin-bottom: 1.6rem;
}

.label label {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

.sub {
  margin-bottom: 1.6rem;
  font-weight: 600;
  font-size: 2.4rem;
}

.desc {
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 22px;
  color: #1c1c1c;
}

.list {
  display: flex;
  display: -ms-flexbox;
  flex-direction: column;
  -ms-flex-direction: column;
  align-items: flex-start;
  gap: 1.2rem;
  margin-top: 1.6rem;
  position: relative;
  margin-left: 2.5rem;
}

.bottom {
  display: flex;
  display: -ms-flexbox;
  align-items: flex-end;
  justify-content: space-between;
  gap: 2.4rem;
}

.list li {
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 22px;
  color: #545454;
}

.logout {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: space-between;
  gap: 2.4rem;
}

.logout .btn button {
  padding: 16px 24px;
  gap: 12px;
  height: 48px;
  background: #ff4e4e;
  border-radius: 6px;
  color: #fff;
  font-weight: 500;
  font-size: 1.6rem;
  white-space: nowrap;
  border: none;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0.9;
  transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
}

.logout .btn button:hover {
  opacity: 1;
  background: #ff4e4e !important;
}

.confirm {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  gap: 16px;
}

.helpers h2 {
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 19px;
  letter-spacing: 0.08em;
  color: #1c1c1c;
}

.helpers h3 {
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 15px;
  color: #717171;
  margin-top: 0.8rem;
}

.notifications {
  gap: 24px;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e2e2e2;
  padding: 1.6rem 0;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
}

.lead h2,
.actions h2 {
  font-weight: 500;
  font-size: 2rem;
  line-height: 24px;
  letter-spacing: 0.08em;
  color: #383838;
  margin-bottom: 0;
}

.lead h3 {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 17px;
  color: #545454;
  margin-top: 0.8rem;
}

.actions {
  gap: 5.5rem;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: flex-start;
}

.actions > div {
  min-width: 58px;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: center;
}

.customBtn {
  white-space: nowrap;
}

.customBtn:hover {
  background-color: var(--platform-secondary-600) !important;
}

/* Button:hover {
  background-color: var(--platform-secondary-600) !important;
} */

.validate__error {
  color: rgb(247, 50, 50);
  font-style: italic;
  font-weight: 500;
  margin-top: -1.7rem;
}

@media screen and (max-width: 950px) {
  .form__control {
    flex-direction: column;
    -ms-flex-direction: column;
  }

  .bottom {
    flex-direction: column;
  }

  .bottom .list {
    width: 100%;
    text-align: left;
    padding-left: 3rem;
  }

  .bottom .btn {
    width: 100%;
  }
}
