.label {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 2.4rem 3.2rem;
  justify-content: space-between;
  gap: 0.8rem;
  cursor: pointer;
  box-shadow: 0px 10px 15px -3px #0000000a;
  background-color: var(--camsol-white);
  border: 2px solid transparent;
  border-radius: 4px;
  transition: border-color 300ms ease;
}

.label:has(input:checked) {
  border-color: #e60369;
}

.label input {
  accent-color: var(--platform-secondary-600);
}

.label span {
  display: block;
  font-size: 2rem;
  font-weight: 600;
  line-height: 30px;
  text-transform: capitalize;
}

.label input {
  width: 1.5rem;
  height: 1.5rem;
}
