.toggle {
  cursor: pointer;
  padding: 2px 4px 2px 0px;
  gap: 8px;
  width: 48px;
  height: 24px;
  background: #c5c5c5;
  border-radius: 15px;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  position: relative;
  transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
}

.toggle.toggled {
  background: #5c187a;
}

.toggle .bubble {
  width: 16px;
  height: 16px;
  background: #ffffff;
  border-radius: 50%;
  position: absolute;
  left: 4px;
  transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
}

.toggle.toggled .bubble {
  left: calc(100% - 20px);
}
