.body {
  padding: 2rem 3.5rem;
  display: flex;
  align-items: flex-start;
  gap: 2rem;
  min-height: 100vh;
  padding-top: 15rem;
}

@media screen and (max-width: 768px) {
  .body {
    flex-direction: column;
    padding-inline: 0;
  }
}
