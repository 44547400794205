.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.heading h2 {
  font-weight: 800;
  font-size: 32px;
  line-height: 39px;
  letter-spacing: 0.08em;
  color: #1c1c1c;
  margin-top: 1.6rem;
  margin-bottom: 3.4rem;
}

.p__tag {
  margin-bottom: 2.4rem;
}

.video {
  width: 100%;
  /* aspect-ratio: 4/3; */
  aspect-ratio: 7/4;
  /* aspect-ratio: 16/10; */
  position: relative;
  border-radius: 1rem;
  overflow: hidden;
}

.video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--camsol-neutral-50);
  cursor: pointer;
  transition: all 300ms ease;
}

.video span:hover {
  scale: 1.1;
}

.video span:active {
  scale: 0.9;
}

.hide {
  opacity: 0;
}

.video:hover .hide {
  opacity: 1;
}

.sub__title {
  font-weight: 600;
  font-size: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.08em;
  color: #00090d;
  margin: 2.4rem 0;
}

.description {
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #545454;
}
