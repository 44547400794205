.side__panel {
  display: flex;
  display: -ms-flexbox;
  flex-direction: column;
  -ms-flex-direction: column;
  align-items: center;
  justify-content: center;
  height: fit-content;
}

.side__panel .image {
  overflow: hidden;
  overflow: -moz-hidden-unscrollable;
  border-radius: 50%;
  width: 150px;
  height: 150px;
}

.side__panel .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
}

.side__panel h2 {
  font-weight: 700;
  color: #000;
  margin-bottom: 0.8rem;
  font-size: 16px;
  text-align: center;
  margin-top: 2.4rem;
}

.side__panel h3 {
  text-align: center;
  margin-bottom: 2.4rem;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 15px;
  color: #383838;
}

.side__panel .info {
  padding: 2.4rem 0;
  width: 100%;
}

.svg {
  width: 100%;
}

.data {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  gap: 0.8rem;
}

.data h4 {
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 15px;
}

.data h4 span {
  font-weight: 300;
}

.info .data:not(:last-of-type) {
  margin-bottom: 2.4rem;
}

.btn {
  font-weight: 500;
}

.btn:hover {
  background-color: var(--platform-secondary-600) !important;
}
