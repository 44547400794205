.all__courses {
  padding: 32px var(--px);
  padding-top: 15rem;
}

.head h2 {
  font-weight: 800;
  font-size: 4rem;
  line-height: 4.8rem;
  color: var(--camsol-black);
  margin-bottom: 1.6rem;
}

.head h3 {
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: var(--camsol-black);
  margin-bottom: 2.4rem;
}

.filter__sort {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: space-between;
  gap: 3.2rem;
  margin-bottom: 2.4rem;
}

.sort {
  position: relative;
}

.sort__drop {
  width: 155px;
  max-height: 160px;
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  display: -ms-flexbox;
  flex-direction: column;
  -ms-flex-direction: column;
  align-items: flex-start;
  padding: 0 0;
  position: absolute;
  right: 0;
  margin-top: 1.1rem;
  z-index: 99;
  overflow: hidden;
}

.sort__drop > button {
  /* outline: 1px solid blue !important; */
  background: transparent;
  border: none;
  outline: none;
  font-weight: 400;
  font-size: 1.6rem;
  color: #000e14;
  white-space: nowrap;
  cursor: pointer;
  padding: 1.1rem 1rem;
  width: 100%;
  text-align: left;
  transition: background-color 150ms ease;
}

.sort__drop > button:hover {
  background: var(--camsol-neutral-100);
}

.sort__drop > .sortActive {
  background: var(--camsol-neutral-200) !important;
}

.courses__filter {
  position: relative;
}

.courses__filter .filter__options {
  position: absolute;
  top: 0;
  left: 0;
  width: 320px;
  transition: left 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  background: #fff;
}

.courses__filter .filter__options.hide {
  left: -200vh;
}

.courses__gallery {
  display: grid;
  display: -ms-grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 32px;
  position: absolute;
  left: 320px;
  width: calc(100% - 320px);
  transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  top: 0;
  padding-bottom: 32px;
}

.courses__gallery.full {
  width: 100%;
  left: 0;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

@media screen and (max-width: 1000px) {
  .courses__filter .filter__options {
    z-index: 99;
  }

  .courses__gallery {
    left: 0;
    width: 100%;
    position: relative;
    grid-template-columns: 1fr 1fr 1fr;
  }

  /* .courses__filter::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
  } */
}

@media screen and (max-width: 800px) {
  .courses__gallery.full {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 650px) {
  .courses__gallery.courses__gallery {
    grid-template-columns: 1fr;
  }

  .courses__filter .filter__options {
    z-index: 99;
    width: 100%;
  }
}
