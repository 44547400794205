.card__container {
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  padding: 2.4rem;
  border-bottom: 4px solid var(--platform-secondary-600);
  background: #ffffff;
  gap: 16px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  overflow-x: hidden;
  cursor: pointer;
}

.card__container > div > img {
  width: 80px;
  height: 80px;
  object-fit: cover;
}

.title {
  max-width: 390px;
  font-weight: 800;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: 0.08em;
  color: #000000;
  text-transform: capitalize;
}

.participants__instructor {
  border-bottom: 1px solid #c5c5c5;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  white-space: nowrap;
  -ms-flex-wrap: wrap;
  padding-bottom: 1.6rem;
}

.participants__instructor_p {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #8d8d8d;
  margin-bottom: 0.8rem;
}

.participants {
  display: flex;
  flex-direction: column;
}

.about__course {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
}

.course__duration {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: #717171;
}

.lessons {
  display: flex;
  align-items: center;
  padding: 0;
  gap: 0.8rem;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: #717171;
}

.languages {
  display: flex;
  align-items: center;
}

.cat {
  color: var(--platform-secondary-700);
}

.lang {
  display: flex;
  align-items: center;
  padding-left: 0.8rem;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: #717171;
}

.lang span:not(:last-child) {
  padding-right: 0.8rem;
}

@media screen and (max-width: 607px) {
  .title {
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.08em;
  }

  .course__name {
    width: fit-content;
  }

  .course__duration {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #717171;
  }

  .lessons {
    display: flex;
    align-items: center;
    padding: 0;
    gap: 0.8rem;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #717171;
  }

  .languages {
    display: flex;
    align-items: center;
  }
}
