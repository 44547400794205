.accordion__item {
  list-style: none;
  display: flex;
  flex-direction: column;
}

.accordion__item__title {
  width: 100%;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.accordion__item__container {
  transition: height 0.5s ease-in-out;
  overflow: hidden;
  width: 100%;
  overflow: -moz-hidden-unscrollable;
}

.accordion__item__content {
  width: 100%;
  /* outline: 1px solid red; */
  opacity: 0;
  transition: 1s ease-in-out;
}

.accordion__item__content.animate {
  opacity: 1;
  transition: 1.5s ease-in-out;
}
