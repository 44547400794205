.body {
  width: 70%;
  /* outline: 1px solid brown; */
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-items: flex-start;
  align-items: flex-start;
  font-weight: 600;
  font-size: 1.5rem;
  padding-bottom: 2rem;
}

.back {
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--platform-secondary-700);
}

.back::after {
  content: '';
  position: absolute;
  z-index: -1;
  width: 35px;
  aspect-ratio: 1/1;
  border-radius: 100%;
  background-color: var(--camsol-neutral-200);
  top: 50%;
  left: 50%;
  transform: translate(-45%, -52%);
  opacity: 0;
  transition: opacity 300ms ease;
}

.back:is(:hover)::after {
  opacity: 0.2;
}

.back:active::after {
  background-color: var(--camsol-neutral-400);
}

.header {
  font-size: 3rem;
  font-weight: 600;
}

.nextLesson {
  width: 100%;
  background: rgba(56, 14, 74, 0.1);
  border-radius: 1rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
  margin-bottom: 1rem;
}

.nextLesson p {
  color: var(--platform-secondary-700);
}

.custom {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: fit-content !important;
  max-width: 235px;
}

.custom:hover {
  background-color: var(--platform-secondary-600) !important;
}

.module {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  margin-bottom: 1rem;
}

.module h2 {
  font-size: 2.2rem;
  font-weight: 600;
}

.topics {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  border-radius: 0.8rem;
  overflow: hidden;
}

.topic {
  background-color: rgba(56, 14, 74, 0.03);
  width: 100%;
  /* border-radius: 0.5rem; */
  display: flex;
  justify-content: flex-start;
}

.tDetails {
  width: 96%;
  display: flex;
  padding: 0.4rem 0.5rem;
  padding-left: 1rem;
  align-items: center;
  justify-content: space-between;
}

.tDetails > div {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.tDetails > div > p {
  color: var(--camsol-neutral-400);
}

.radioCont {
  width: 4%;
  min-width: 45px;
  /* outline: 1px solid blue; */
  border-right: 1px solid var(--platform-secondary-200);
  margin-left: auto;
  display: grid;
  place-content: center;
}

@media screen and (max-width: 768px) {
  .body {
    width: 100%;
    padding-inline: 2rem;
  }
  .back {
    display: none;
  }
  .header {
    font-size: 24px;
    font-weight: 600;
  }
  .custom,
  .nextLesson {
    font-size: 12px;
  }
  .module > h2 {
    font-size: 16px;
  }
  .radioCont {
    width: 8%;
  }
  .tDetails {
    width: 92%;
    position: relative;
    overflow: hidden;
  }

  .tDetails > div > p {
    font-size: 12px;
  }
  .tDetails .custom {
    position: absolute;
    inset: 0;
    width: 100% !important;
    opacity: 0;
  }
  .topics {
    gap: 0.7rem;
  }
}
