.wrapper {
  width: 30%;
  position: relative;
  min-height: 1087px;
}

.body {
  width: 100%;
  min-height: 90vh;
  background: var(--camsol-white);
  border-radius: 0.5rem;
  box-shadow: var(--shadow-md);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-items: flex-start;
  font-weight: 600;
  font-size: 1.5rem;
  padding-bottom: 2rem;
  position: absolute;
}

.banner {
  width: 100%;
  aspect-ratio: 3/2;
  object-fit: cover;
}

.container {
  padding-inline: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
}

.title {
  font-weight: 700;
  font-size: 1.6rem;
  display: flex;
  gap: 0.5rem;
}

.title svg,
.title span {
  font-weight: 700;
  color: var(--platform-secondary-700);
}

.instructor {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.instructor img {
  aspect-ratio: 1/1;
  width: 2.5rem;
  border-radius: 100%;
  object-fit: cover;
}

.instructor span {
  font-weight: 700;
}

.divider {
  height: 1px;
  width: 100%;
  background: -webkit-radial-gradient(
    50% 50%,
    ellipse closest-side,
    #afafaf,
    rgba(255, 42, 112, 0) 90%
  );
}

.center {
  align-self: center;
}

.detail {
  display: flex;
  gap: 0.7rem;
  align-items: center;
  justify-content: flex-start;
}

.detail h5 {
  font-weight: 600;
  font-size: 1.5rem;
}

.btn {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.btn2:hover {
  background-color: var(--platform-secondary-600) !important;
}

@media screen and (max-width: 768px) {
  .wrapper {
    width: 100%;
  }
  .body {
    position: relative;
    box-shadow: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .banner {
    border-radius: 0;
  }
}

@media screen and (min-width: 720px) and (max-width: 1020px) {
  .detail {
    align-items: flex-start;
  }
}
