.body {
  width: 70%;
  max-width: 1280px;
  background-color: var(--camsol-white);
  padding: 91px 80px;
  padding-top: 44px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  user-select: none;
  gap: 5rem;
}

.close {
  align-self: flex-end;
  cursor: pointer;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
}

.iconContainer {
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1/1;
  background-color: var(--camsol-primary-50);
  border-radius: 12px;
  position: relative;
  isolation: isolate;
}

.text {
  display: flex;
  width: 350px;
  flex-direction: column;
  gap: 16px;
}

.text > h2 {
  font-size: 32px;
  font-weight: 800;
  line-height: 38.73px;
  text-align: center;
  color: #1c1c1c;
}

.text > p {
  font-size: 18px;
  font-weight: 400;
  line-height: 21.78px;
  text-align: center;
  color: var(--camsol-neutral-600);
}

.input {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.input > span {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
}

@media screen and (max-width: 768px) {
  .body {
    width: 90%;
    padding: 4rem 2.5rem;
  }

  .input {
    width: 100%;
  }
}
