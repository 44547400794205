.body {
  width: 100%;
  padding: 0 6vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  margin-bottom: 48px;
}

.title {
  text-align: center;
  width: 1008px;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.title > h1 {
  font-size: 64px;
  line-height: 65px;
  font-weight: 700;
}

.title > p {
  font-size: 1.8rem;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}

.enrollBtn:hover {
  background: var(--platform-secondary-600) !important;
}

.customBtn {
  border: 2px solid transparent !important;
  color: var(--platform-secondary-600) !important;
}

.customBtn:hover {
  background: none !important;
  border: 2px solid var(--platform-secondary-600) !important;
}

.customBtn:active {
  background: var(--platform-secondary-200);
}

.container {
  position: relative;
  /* outline: 3px solid blue; */
}

.videoContainer,
.mobileVideoContainer {
  /* outline: 1px solid orange; */
  position: relative;
  display: flex;
  align-items: center;
  gap: 2.8rem;
  --w: calc(calc(95.5vw - calc(112px * 2)) / 5);
  isolation: isolate;
}

.videoContainer {
  --w: calc((1216px - (4 * 32px)) / 5);
  width: 1216px;
  overflow: hidden;
  gap: 32px;
}

.mobileVideoContainer {
  display: none;
}

.video {
  height: var(--w, 20rem);
  width: var(--w, 20rem);
  background-color: transparent;
  border-radius: 1rem;
  overflow: hidden;
  position: relative;
}

.video::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 99;
  transition: opacity 300ms ease;
}

.video:is(:hover)::after {
  opacity: 0;
}

.video video {
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: all 500ms ease;
}

.double {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.double .video {
  transition: height 300ms ease;
}

.double .video video {
  width: 100%;
  object-fit: cover;
}

.double:has(.video:hover) .video {
  height: calc(var(--w) * 0.7);
}

.double:has(.video:hover) .video video {
  width: var(--w);
  object-fit: cover;
}

.double .video:hover {
  height: calc(var(--w) * 1.5) !important;
}

.full {
  height: calc(var(--w) * 2.5);
  width: var(--w);
  background-color: transparent;
  border-radius: 1rem;
}

.full .video {
  height: 100% !important;
}

@media screen and (max-width: 1350px) {
  .videoContainer {
    max-width: 100%;
    width: 100%;
    --w: calc((80vw - (4 * 32px)) / 5);
    /* outline: 1px solid orange; */
  }
}

@media screen and (max-width: 1020px) {
  .body {
    gap: 5rem;
  }
  .videoContainer {
    --w: calc((90vw - (4 * 16px)) / 5);
    /* outline: 1px solid blue; */
    gap: 16px;
  }
  .title {
    width: 70%;
  }
  .title > h1 {
    font-size: 48px;
  }
}

@media screen and (max-width: 800px) {
  .title {
    width: 100%;
    gap: 2.5rem;
    align-items: center;
  }

  .title > h1 {
    font-size: 32px;
    font-weight: 800;
    line-height: 37px;
    text-align: center;
    width: 350px;
    letter-spacing: 0.08em;
  }

  .title > p {
    font-size: 14px;
    line-height: 17px;
    text-align: center;
  }

  .buttons {
    flex-direction: column;
    margin-top: 0.5rem;
  }

  .mobileVideoContainer {
    --w: 17rem;
    --transition: transform 700ms ease;
    gap: 1.2rem;
    width: 100vw;
    height: 36rem;
    /* outline: 1px solid orange; */
    display: flex;
    justify-content: center;
    overflow: -moz-hidden-unscrollable;
    overflow: hidden;
    position: relative;
  }

  .mobileVideoContainer .video {
    flex-shrink: 0;
    position: absolute;
  }
  .mobileVideoContainer .video video {
    width: 100%;
    object-fit: cover;
  }

  .videoContainer {
    display: none;
  }

  .video::after {
    background-color: rgba(0, 0, 0, 0.2);
  }

  .video:is(:hover)::after {
    opacity: 1;
  }

  .videoActive::after {
    opacity: 0;
  }

  .videoActive:is(:hover)::after {
    opacity: 0 !important;
  }

  .video {
    height: 30rem;
  }

  .mobileVideo:not(.videoActive, .videoLeft, .videoRight) {
    opacity: 0;
  }

  .videoActive {
    transform: scale(1) scaleY(1.2);
    transition: var(--transition);
    opacity: 1 !important;
    z-index: 30;
  }

  .videoLeft {
    transform: translateX(-170px) scale(0.8);
    transition: var(--transition);
    z-index: 25;
    opacity: 1 !important;
  }

  .videoRight {
    transform: translateX(170px) scale(0.8);
    transition: var(--transition);
    z-index: 25;
    opacity: 1 !important;
  }

  .nextLeft {
    transform: translateX(-170px) scale(0.8);
    z-index: 20;
    transition: opacity 1200ms ease;
    opacity: 0 !important;
  }

  .nextRight {
    transform: translateX(170px) scale(0.8);
    transition: opacity 1200ms ease;
    z-index: 20;
    opacity: 0 !important;
  }
}
