.image {
  position: absolute;
  top: -8rem;
  right: 12rem;
  animation: rotation 12s infinite linear;
  height: 20.4rem;
  width: 20.6rem;
  margin: auto;
  z-index: 100;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@media screen and (min-width: 1089px) and (max-width: 1350px) {
  .image {
    height: 16rem;
    width: 16rem;
  }
}

@media screen and (min-width: 1021px) and (max-width: 1088px) {
  .image {
    height: 16rem;
    width: 16rem;
  }
}

@media screen and (min-width: 801px) and (max-width: 1020px) {
  .image {
    height: 15rem;
    width: 15rem;
    top: -5rem;
  }
}

@media screen and (max-width: 800px) {
  .image {
    height: 10rem;
    width: 10rem;
    top: -2rem;
    left: 5rem;
    margin: auto;
  }
}

@media screen and (max-width: 450px) {
  .image {
    height: 10rem;
    width: 10rem;
    top: -2rem;
    left: 5rem;
  }
}

@media screen and (max-width: 280px) {
  .image {
    height: 10rem;
    width: 10rem;
    top: -2rem;
    left: 5rem;
  }
}
