.popup__wrapper {
  display: flex;
  display: -ms-flexbox;
  max-width: 320px;
  background: #ffffff;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  overflow: hidden;
  position: fixed;
  z-index: 9999;
  bottom: 2rem;
  right: -100vw;
  transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  min-height: 50px;
}

.popup__wrapper.show {
  right: 2rem;
}

.state {
  width: 47px;
  min-height: 100%;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: center;
}

.error {
  background-color: var(--camsol-status-error);
}

.success {
  background-color: var(--camsol-status-success);
}

.content {
  width: 100%;
  display: flex;
  display: -ms-flexbox;
  flex-direction: column;
  -ms-flex-direction: column;
  padding: 1rem 0;
  padding-left: 0.8rem;
}

.content h1 {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.08em;
  color: #545454;
  margin-bottom: 0.4rem;
}

.content p {
  font-weight: 400;
  font-size: 12px !important;
  line-height: 15px !important;
  letter-spacing: 0.08em;
  text-align: left !important;
  color: #a9a9a9;
}

.cancel {
  min-height: 100%;
  border-left: 1px solid #f7eefb;
  display: flex;
  display: -ms-flexbox;
  justify-content: center;
  align-items: center;
  padding: 0 12px !important;
  cursor: pointer;
}

.cancel p {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #8d8d8d;
}
