.controller button {
  padding: 1.2rem 1.6rem;
  gap: 1.6rem;
  height: 4.8rem;
  border: 1px solid var(--platform-secondary-900);
  border-radius: 4px;
  background: transparent;
  cursor: pointer;
  font-weight: 400;
  font-size: 1.6rem;
  color: var(--platform-secondary-900);
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  transition: 0.2s ease-in-out;
}

.controller button:hover {
  background: #fff !important;
}
