.icons {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  gap: 3.2rem;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  margin: 3rem 0;
  padding: 2rem;
  border: 1px solid black;
}

.icon {
  padding: 1rem;
  border: 1px solid black;
  border-radius: 3px;
}
