.container {
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 2;
}

.img {
  width: 80px;
  height: 80px;
  border-radius: 8px;
  overflow: hidden;
}

.img img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 1/1;
}

.course__name {
  width: 192px;
  min-height: 15px;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.08em;
  color: #717171;
}

.title {
  min-width: 300px;
  width: 400px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.08em;
  color: #000000;
  margin-bottom: 0.4rem;
}

@media screen and (min-width: 720px) and (max-width: 1020px) {
  .title {
    width: 300px;
  }
}

@media screen and (max-width: 607px) {
  .title {
    /* width: fit-content; */
    width: 90px;
    font-size: 10px;
    line-height: 12px;
  }
  .course__name {
    width: fit-content;
  }
}

@media screen and (max-width: 768px) {
  .title,
  .course__name {
    font-size: 12px;
    width: 100%;
  }
  .title {
    min-width: 20%;
    max-width: 90px;
  }
  .img {
    width: 80px;
    height: fit-content;
  }
  .container {
    width: 60%;
  }
  .course {
    width: 80%;
  }
}

/* @media screen and (max-width: 480px) {
  .title,
  .course__name {
    display: none !important;
  }
} */
