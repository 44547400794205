.button {
  padding: 1rem 2rem;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: center;
  gap: 1.2rem;
  height: 56px;
  border-radius: 6px;
  color: #fff;
  background: var(--platform-secondary-700);
  font-weight: 600;
  font-size: 2rem;
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  white-space: nowrap;
}

.button.secondary {
  color: var(--main-color);
  background: #fff;
  border: 2px solid #a9a9a9;
}

/* .button.secondary:hover {
  background: var(--main-color);
  color: #fff;
} */

.button.primary:hover {
  background: var(--platform-secondary-600);
}

.button.primary:active {
  background: var(--platform-secondary-900);
  transition: none;
}
.button.secondary:active {
  transition: none;
}

.button:disabled {
  background: var(--camsol-neutral-100);
  color: var(--camsol-neutral-300);
  cursor: not-allowed;
}

.button:disabled:hover {
  background: var(--camsol-neutral-100) !important;
  color: var(--camsol-neutral-300) !important;
  cursor: not-allowed;
}
