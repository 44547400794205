.body {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.9rem;
  margin-bottom: 0.5rem;
}

.header span {
  background-color: var(--platform-secondary-700);
  font-size: 1.2rem;
  font-weight: 600;
  display: grid;
  place-items: center;
  padding: 0rem 0.6rem;
  border-radius: 0.25rem;
  font-variant-numeric: tabular-nums;
  color: var(--camsol-white);
}

.header h6 {
  font-weight: 700;
  font-size: 1.5rem;
}

.topics {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0rem;
}

.topic {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
  cursor: pointer;
}

.topic > span {
  color: var(--platform-secondary-700);
}

.details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 0px;
  gap: 0rem;
}

.details > p {
  padding: 0;
  line-height: 2rem;
  font-weight: 500;
  font-size: 1.5rem;
}

.details > span {
  color: var(--camsol-neutral-500);
  font-weight: 600;
  font-size: 1.1rem;
}

.customAccordion {
  padding: 1.5rem 1rem;
  border-radius: 0.5rem;
  box-shadow: var(--shadow-md);
}
