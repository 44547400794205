.body {
  background: var(--camsol-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.head h1 {
  font-size: 4rem;
  font-weight: 800;
  color: var(--platform-secondary-700);
  text-align: center;
  letter-spacing: 3.2px;
  padding-bottom: 16px;
  margin-top: 30px;
  line-height: normal;
}

.head p {
  font-size: 2rem;
  text-align: center;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
  margin-bottom: 32px;
}

/* .head {
  margin-bottom: 117px;
} */

.footer p {
  color: var(--camsol-neutral-800, #1c1c1c);
  font-size: 1.2rem;
  font-weight: 400;
  letter-spacing: 0.24px;
  margin-block: 32px;
}

.body svg {
  width: 100%;
}

@media screen and (max-width: 620px) {
  .body > h1 {
    font-size: 38px;
  }

  .body > p {
    font-size: 16px;
  }
}
