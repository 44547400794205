.video__module__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background: rgba(56, 14, 74, 0.03);
  margin: 0.5rem 0;
  padding-right: 1.6rem;
}

.video__status {
  display: flex;
  align-items: center;
  width: 90%;
}

.vid__icon {
  margin: 0 0.8rem;
}

.video__text {
  width: 697px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #8d8d8d;
}

/* Button:hover {
  background-color: var(--platform-secondary-600);
} */

@media screen and (max-width: 968px) {
  .video__status {
    max-width: 53rem;
  }

  .video__text {
    width: 100%;
  }
}

@media screen and (max-width: 400px) {
  .video__status {
    max-width: 53rem;
  }

  .video__text {
    width: 100%;
  }
}
