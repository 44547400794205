.body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 3rem;
  padding: 64px 0;
  /* min-height: 80vh; */
}

.title {
  font-weight: 800;
  font-size: 4.5rem;
  color: var(--camsol-neutral-900);
  margin-bottom: 1.8rem;
}

.content {
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 2rem;
  /* outline: 1px solid green; */
}

.video {
  width: 65%;
  /* aspect-ratio: 4/3; */
  aspect-ratio: 7/5;
  /* aspect-ratio: 16/10; */
  position: relative;
  border-radius: 1rem;
  overflow: hidden;
}

.video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--camsol-neutral-50);
  cursor: pointer;
  transition: all 300ms ease;
}

.video span:hover {
  scale: 1.1;
}

.video span:active {
  scale: 0.9;
}

.hide {
  opacity: 0;
}

.video:hover .hide {
  opacity: 1;
}

.details {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.details h5 {
  font-weight: 600;
  color: var(--camsol-neutral-900);
  font-size: 2.5rem;
}

.trailer {
  width: 100%;
  display: flex;
  gap: 0.5rem;
  background-color: var(--camsol-white);
  box-shadow: var(--shadow-md);
  font-weight: 700;
  color: var(--camsol-black);
  border-radius: 0.5rem;
  padding: 1.5rem;
  font-size: 1.6rem;
  cursor: pointer;
  transition: all 150ms ease;
}

.trailer:active {
  box-shadow: var(--shadow-sm);
}

.trailer span {
  color: var(--platform-secondary-700);
}

.plan {
  /* aspect-ratio: 7/7; */
  overflow-y: auto;
  padding: 1rem 0;
  padding-inline: 0.5rem;
}

.plan::-webkit-scrollbar {
  width: 0.5rem;
}

.plan::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  background: var(--platform-secondary-700);
  transition: all 300ms ease;
}

.plan::-webkit-scrollbar-thumb:hover {
  background: var(--platform-secondary-500);
}

.plan::-webkit-scrollbar-thumb:active {
  background: var(--platform-secondary-600);
}

.plan::-webkit-scrollbar-track {
  border-radius: 0.5rem;
  background-color: var(--platform-secondary-50);
}

.button {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1.8rem;
}

.custom {
  font-weight: 600 !important;
  border: 2px solid #00090d;
  background: transparent;
  color: #00090d;
}

.custom:hover {
  color: var(--camsol-white);
  border: 1.5px solid var(--platform-secondary-700);
}

@media screen and (max-width: 768px) {
  .title {
    font-size: 3rem;
  }

  .content {
    flex-direction: column;
  }

  .video {
    width: 100%;
    aspect-ratio: 16/10;
    height: auto;
  }

  .details {
    width: 100%;
  }
}
