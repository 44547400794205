.content__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 24px 16px;
  gap: 12px;
  max-width: 396px;
  min-height: 369px;
  background: #ffffff;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  margin-top: 16px;
}

.content__wrapper h3 {
  font-weight: 700;
  font-size: 16px;
  line-height: 169.02%;
  text-align: justify;
  color: #000000;
}

.content__wrapper p {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.content__wrapper textarea {
  padding: 16px;
  width: 100%;
  min-height: 125px;
  background: #ffffff;
  border: 1.5px solid #c5c5c5;
  border-radius: 8px;
  resize: none;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  outline: none;
  letter-spacing: 0.02em;
  color: var(--camsol-primary-900);
}

.icons {
  display: flex;
  align-items: center;
  gap: 4px;
}

.icon {
  color: var(--platform-secondary-700);
  transition: 0.3s ease-in-out;
  cursor: pointer;
  transform: scale(1);
}

.icon.scale {
  animation: scale 1.2s linear forwards;
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
