.container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.img {
  width: 32px;
  height: 32px;
  border-radius: 64px;
  overflow: hidden;
  border: 2px solid #dbb1ed;
}

.img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.instructor__role {
  width: 104px;
  height: 15px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #545454;
}

.instructor__name {
  width: 104px;
  height: 19px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.08em;
  color: #000000;
}
