.body {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  border-radius: 1rem;
  box-shadow: var(--shadow-xl);
  color: var(--camsol-neutral-800);
  flex-shrink: 0;
  padding: 1.5rem;
  height: 100%;
}

/* .body, .body * {
    outline: 1px solid green;
} */

.image {
  height: 250px;
  width: 100%;
  object-fit: cover;
  border-radius: 1rem;
}

.body > h6 {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 58px;
  /* outline: 1px solid blue; */
}

.body > p {
  font-size: 1.3rem;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 64px;
}

.details {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-weight: 500;
  font-size: 1.2rem;
  color: var(--camsol-neutral-600);
}

.details span {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.custom {
  border-color: var(--platform-secondary-500) !important;
  color: var(--platform-secondary-500) !important;
  font-weight: 500;
  transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  font-weight: 500;
}

.custom:hover {
  color: white !important;
  background: var(--platform-secondary-500);
}

@media screen and (max-width: 768px) {
  .image {
    height: 40%;
  }
}
