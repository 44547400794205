.container {
  width: 100%;
  min-width: 370px;
  /* flex-basis: 100%; */
  /* margin: 15px; */
  margin-bottom: 24px;
  padding: 24px 23px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #fff;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
  box-shadow: 0px 15px 50px -12px rgba(29, 8, 37, 0.2);
  -webkit-box-shadow: 0px 15px 50px -12px rgba(29, 8, 37, 0.2);
}

.review__text {
  font-family: var(--inter-font);
  font-weight: 400;
  font-size: 16px;
  line-height: 19.36px;
  text-align: center;
  margin-bottom: 24px;
}

.name {
  font-family: var(--inter-font);
  font-weight: 600;
  font-size: 24px;
  line-height: 29.05px;
  letter-spacing: 8%;
  text-align: center;
  margin-top: 24px;
}

.course__name {
  font-family: var(--inter-font);
  font-weight: 400;
  font-size: 18px;
  line-height: 21.78px;
  text-align: center;
  margin: 8px 0;
}

.review {
  display: flex;
  gap: 0.3rem;
  align-items: center;
}
