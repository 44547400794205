.section {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.section > div {
  width: 23%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.section h2 {
  font-weight: 800;
  font-size: 32px;
  line-height: 39px;
  letter-spacing: 0.08em;
}

.courses {
  width: 76% !important;
  flex-direction: row !important;
  display: flex;
}

.courses > a {
  width: 33%;
}

.section p {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: var(--camsol-neutral-600);
}

.chevron_div {
  color: var(--platform-secondary-700);
  display: flex;
  gap: 36px;
}

.chevron_div > button {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  border: 1px solid;
  border-radius: 4px;
  color: var(--platform-secondary-700);
  background: #fff;
}

@media screen and (max-width: 768px) {
  .section {
    flex-direction: column;
    gap: 2rem;
  }

  .section > div {
    width: 100% !important;
  }

  .courses > div:last-child {
    display: none;
  }

  .courses > div {
    width: 50%;
  }

  .info > p {
    display: none;
  }

  .info > h2 {
    font-size: 28px;
  }

  .info {
    flex-direction: row !important;
  }

  .chevron_div > button {
    border-color: transparent;
    background: #fff;
  }

  .chevron_div > button:hover {
    border-color: var(--platform-secondary-700);
  }
}
