.wrapper {
  width: 100%;
  padding: 2.4rem var(--px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.body {
  max-width: calc(100vw - var(--px));
  width: 100%;
  gap: 3rem;
  display: flex;
  flex-direction: column;
}

.title {
  max-width: calc(100vw - var(--px));
  width: 100%;
  align-self: center;
  max-width: 100%;
}

.title > p {
  font-size: 1.25rem;
  color: var(--platform-secondary-700);
  margin-bottom: 0.8rem;
}

.title > h1 {
  font-weight: 700;
  font-size: 6.4rem;
  /* margin-bottom: 0.8rem; */
}

.more {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  font-size: 1.4rem;
}

.swiperPadding {
  padding: 5rem 3rem !important;
  padding-top: 1rem !important;
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.swiperBody {
  width: 105%;
  margin-left: -2.5%;
  max-width: calc(100% + 2.5%);
}

.custom {
  text-decoration: underline;
  padding: 0.28rem 1.5rem !important;
  border-radius: 0 !important;
  font-size: 1.4rem !important;
  border: none !important;
  font-weight: 600;
  color: var(--platform-secondary-600) !important;
}

.custom:hover {
  border: 2px solid var(--platform-secondary-600) !important;
  border-radius: 8px !important;
  background: none !important;
}

@media screen and (min-width: 720px) and (max-width: 1020px) {
  .title > h1 {
    font-size: 48px;
  }
  .swiperBody {
    width: 105%;
    max-width: 100vw;
    margin-left: 0%;
  }
}

@media screen and (max-width: 768px) {
  .title > h1 {
    font-size: 3rem;
  }

  .more {
    flex-direction: column;
  }
  .swiperBody {
    width: 100%;
    margin-left: 0%;
    max-width: 100vw;
  }
  .swiperPadding {
    padding-left: 1rem !important;
  }
}
