.body {
  width: 70%;
  max-width: 1280px;
  background-color: var(--camsol-white);
  padding: 91px 80px;
  padding-top: 44px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
}

.close {
  align-self: flex-end;
  cursor: pointer;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  user-select: none;
}

.spinner {
  max-width: 79px;
  margin-top: 4rem;
}

.spinner img {
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: contain;
  animation: rotate 900ms linear infinite;
}

.iconContainer {
  width: 57px;
  height: 57px;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1/1;
  background-color: var(--platform-secondary-600);
  border-radius: 12px;
  position: relative;
  isolation: isolate;
}

.iconContainer span {
  aspect-ratio: 1/1;
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  border-radius: 12px;
  background-color: var(--platform-secondary-600);
  animation: pulse infinite linear 2500ms;
  z-index: -1;
  pointer-events: none;
  user-select: none;
  z-index: -1;
}

.iconContainer .third {
  animation-delay: 0ms;
  opacity: 0.4;
  /* filter: blur(1px); */
  /* z-index: -3; */
}

.iconContainer .second {
  animation-delay: 500ms;
  opacity: 0.6;
  /* filter: blur(1px); */

  /* z-index: -2; */
}

.text {
  display: flex;
  width: 350px;
  flex-direction: column;
  gap: 16px;
}

.text > h2 {
  font-size: 32px;
  font-weight: 800;
  line-height: 38.73px;
  text-align: center;
  color: #1c1c1c;
}

.text > p {
  font-size: 18px;
  font-weight: 400;
  line-height: 21.78px;
  text-align: center;
  color: var(--camsol-neutral-600);
}

.input {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.input > span {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
}

.backBtn {
  border: none !important;
}

@media screen and (max-width: 768px) {
  .body {
    width: 90%;
    padding: 4rem 2.5rem;
  }

  .input {
    width: 100%;
  }
}

@keyframes pulse {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1.8);
    opacity: 0;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
}
