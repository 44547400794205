.body {
  position: relative;
  width: 100%;
  height: 340px;
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  padding: 2.8rem;
  padding-bottom: 0;
  width: 575px;
  border-radius: 16px;
  height: 340px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(78, 0, 35, 0.37) 0.01%,
    rgba(151, 0, 73, 0.9) 97.38%
  );

  /* background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.37) 0.01%,
    rgba(0, 0, 0, 0.9) 97.38%
  ); */
}

.content > div {
  position: absolute;
  bottom: 2.8rem;
}

.heading {
  font-weight: 800;
  font-size: 32px;
  line-height: 39px;
  letter-spacing: 0.08em;
  color: #e5e8e7;
  margin-bottom: 20px;
}

.descr {
  max-height: 66px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #b4b4b4;

  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  width: 512px;
  padding: 1.2rem 0;
  margin-bottom: 5px;
  padding-top: 0px;
}

.icon__wrapper {
  display: flex;
  gap: 16px;
}

.icon {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #8d8d8d;
}

.video {
  border-radius: 16px;
  overflow: hidden;
  width: 575px;
  height: 340px;
}

.video video {
  height: 100%;
}

.text {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  color: #ffffff;
}

.cat {
  color: #ffffff;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 18px;
  gap: 8px;
  line-height: 22px;
}

.videoContainer > img {
  width: 100%;
  height: 340px;
  object-fit: cover;
  border-radius: 16px;
}

@media screen and (max-width: 500px) {
  .body,
  .content,
  .video,
  .videoContainer img {
    height: 200px;
  }

  .descr {
    width: 100%;
  }

  .content {
    width: 100%;
    padding: 20px;
  }

  .video {
    width: 298px;
  }

  .heading {
    font-size: 16px;
    line-height: 2.4rem;
  }

  .descr {
    font-size: 12px;
    display: none;
  }

  .content > div {
    bottom: 1.2rem;
    width: 100%;
    max-width: 264px;
    padding-right: 0;
  }

  .heading {
    line-height: 25px;
    margin-bottom: 8px;
  }

  .text {
    font-size: 14px;
  }

  .content > div > h2,
  .content .icon__wrapper {
    width: 100%;
  }

  .text {
    font-size: small;
    padding-top: 0.2rem;
  }

  .icon__wrapper {
    padding-bottom: 1.2rem;
  }
}
