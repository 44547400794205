.parent__div {
  width: 100%;
  height: 100%;
}

.img__container {
  height: 226px;
  overflow: hidden;
  border-radius: 16px;
  position: relative;
  cursor: pointer;
  isolation: isolate;
}

.overlay {
  position: absolute;
  padding: 1.8rem 2rem;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  transform: translateY(300px);
  transition: 1s ease-in-out;
  border-radius: 16px;
  /* backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px); */
}

.overlay h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  letter-spacing: 0.08em;
  color: #ebeff1;
  margin-bottom: 1.6rem;
}

.overlay p {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #f4f4f4;
}

.img__container:hover .overlay {
  transform: translateY(0px);
}

.tag {
  position: absolute;
  left: 2.7rem;
  top: 1.5rem;
}

.tag h4 {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  color: #af53d6;
}

.img__container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* .data__content {
  height: 100%;
} */

.parent__div h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.08em;
  color: #ebeff1;
  padding-top: 24px;
  padding-bottom: 16px;
}

.students__btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 3rem;
  flex-direction: row-reverse;
}

.images {
  display: flex;
}

.images img {
  width: 34px;
  height: 34px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  object-fit: cover;
}

.images img:nth-child(2) {
  margin-left: -1rem;
}

.images img:nth-child(3) {
  margin-left: -1rem;
}

.students {
  display: flex;
  align-items: center;
  gap: 1.6rem;
  opacity: 0;
  pointer-events: none;
}

.students h3 {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #f4f4f4;
}

.btn a {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  text-decoration: none;
  font-weight: 600;
  color: var(--platform-secondary-700);
  text-wrap: nowrap;
}
