.radio_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.2rem;
}

.label input {
  display: none;
}

.label {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 2.4rem 3.2rem;
  justify-content: space-between;
  gap: 0.8rem;
  box-shadow: 0px 10px 15px -3px #0000000a;
  background-color: var(--camsol-white);
  /* border: 2px solid transparent; */
  border-radius: 4px;
  transition: border-color 300ms ease;
}

.label * {
  opacity: 0.3;
}

.label:has(.show) * {
  opacity: 1;
}

.label:has(.show) {
  background-color: var(--platform-secondary-600);
  /* border-color: var(--platform-secondary-600); */
}

.label:has(.show) span {
  color: var(--camsol-white);
}

.label span {
  display: block;
  font-size: 2rem;
  font-weight: 600;
  line-height: 30px;
  text-transform: capitalize;
}

.circle {
  aspect-ratio: 1/1;
  width: 2rem;
  border-radius: 100%;
  border: 2px solid var(--platform-secondary-700);
  background-color: transparent;
  position: relative;
  pointer-events: none;
}

.label:has(.show) .circle {
  border-color: var(--camsol-white);
}

.dot {
  aspect-ratio: 1/1;
  width: 1rem;
  border-radius: 100%;
  background-color: var(--platform-secondary-700);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: transform 300ms ease;
  transform-origin: left;
}

.label:has(.show) .dot {
  background-color: var(--camsol-white);
}

.show {
  transform: scale(1) translate(-50%, -50%);
}

.hide {
  transform: scale(0) translate(-50%, -50%);
}

.correct,
.wrong {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem 3.2rem;
  box-shadow: 0px 10px 15px -3px #0000000a;
  border-radius: 4px;
}

.correct {
  border-left: 4px solid var(--camsol-status-success);
  --span-color: var(--camsol-status-success);
}

.wrong {
  border-left: 4px solid var(--camsol-status-error);
  --span-color: var(--camsol-status-error);
}

.title {
  display: flex;
  justify-content: space-between;
}

.title > h6 {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 24px;
  color: var(--span-color);
}

.text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}

.text > p {
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 24px;
  text-wrap: balance;
}
