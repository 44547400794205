.body {
  padding: 25px;
  display: flex;
  align-items: stretch;
  gap: 25px;
  width: 100%;
  max-width: 572px;
  max-height: 356px;
  background-color: var(--camsol-white);
  border: 1px solid var(--platform-secondary-200);
  box-shadow: 0px 8px 25px -5px #e603691a;
  border-radius: 1.5rem;
}

.body > * {
  width: 50%;
}

.image {
  object-fit: cover;
  border-radius: 8px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: space-between;
}

.title {
  font-size: 20px;
  font-weight: 700;
  line-height: 24.2px;
  color: var(--platform-secondary-900);
}

.text {
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  color: var(--camsol-neutral-700);
}

.infos {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.info {
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--camsol-neutral-600);
}

@media screen and (max-width: 450px) {
  .body {
    flex-direction: column;
    max-height: fit-content;
  }

  .body > * {
    width: 100%;
  }
}
